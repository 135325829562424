import { combineReducers } from "redux";

import Signin from "../../pages/loginPage/LoginPage.reducer";
import AddRole from "../../components/AddRole/AddRole.reducer";
import Candidates from "../../pages/candidatePage/CandidatePage.reducer";
import Companies from "../../components/CompanyDropdown/CompanyDropdown.reducer";
import Notification from "../../components/Notification/Notification.reducer";
import SuperAdmin from "../../pages/userListPage/UserListPage.reducer";
import Loaders from "./loaders.reducer";
import EditRole from "../../components/EditRole/editRole.slice";
import RoleComments from "../../pages/roleComments/RoleComments.reducer";

export default combineReducers({
    signin: Signin,
    loaders: Loaders,
    addRole: AddRole,
    candidate: Candidates,
    company: Companies,
    notification: Notification,
    superAdmin: SuperAdmin,
    editRole: EditRole,
    roleComments: RoleComments,
});
