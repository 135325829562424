import { Box, Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../pages/loginPage/LoginPage.reducer";
import Sidebar from "../Sidebar/Sidebar";

export default function AppContainer({ children }) {
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    useEffect(() => {
        if (!isEmpty(user) && user.role === "CLIENT" && !user.passwordReset) {
            navigate("/create-password");
        }
    }, [user]);

    return (
        <Box
            width={"100%"}
            maxWidth={"100vw"}
            minHeight={"100vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Sidebar />
            <Stack minHeight="100vh" width="calc(100vw - 260px)">
                {children}
            </Stack>
        </Box>
    );
}
