import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import hqLogo from "../../assets/hqLogo.png";
import { setErrorNotification } from "../../components/Notification/Notification.reducer";
import { authLogin, selectUser } from "../loginPage/LoginPage.reducer";
import { changePassword } from "../userListPage/UserListPage.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import ButtonTextWithLoading from "../../components/CustomMUIComponents/ButtonTextWithLoading";
import { Navigate, useNavigate } from "react-router-dom";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import { isEmpty } from "lodash";

export default function CreatePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const isPasswordSubmitting = useSelector(
        checkIfLoading(changePassword.type)
    );
    const isUserLoading = useSelector(checkIfLoading(authLogin.type));

    const [password, setPassword] = useState({
        new: "",
        confirm: "",
    });

    const handleChangePassword = () => {
        if (password.new.trim() === "" || password.confirm.trim() === "") {
            dispatch(
                setErrorNotification(
                    "Please enter both new and confirm passwords."
                )
            );
        } else if (password.new !== password.confirm) {
            dispatch(setErrorNotification("Passwords do not match."));
        } else {
            dispatch(
                changePassword({
                    action: changePassword.type,
                    password: password.confirm,
                    navigate,
                })
            );
        }
    };

    const handlePasswordChange = (field, value) => {
        setPassword((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    if (isUserLoading) {
        return (
            <Box
                width={"100%"}
                minHeight={"100vh"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <CircularProgress />
            </Box>
        );
    }
    if (!isEmpty(user) && user.role === "CLIENT" && user.passwordReset) {
        return <Navigate to="/" />;
    }
    return (
        <Box
            width="100%"
            minHeight="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="#fff"
        >
            <Stack width={400}>
                <Box
                    component="img"
                    sx={{
                        height: "auto",
                        width: 180,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                    }}
                    alignSelf="center"
                    alt="hirequotient-logo"
                    src={hqLogo}
                />
                <Typography
                    fontSize={20}
                    fontWeight={700}
                    color="#000"
                    mt={4}
                    mb={2}
                >
                    Create new password
                </Typography>
                <Stack spacing={2}>
                    <PasswordInput
                        label="New password"
                        value={password.new}
                        onChange={(e) =>
                            handlePasswordChange("new", e.target.value)
                        }
                    />
                    <PasswordInput
                        label="Confirm password"
                        value={password.confirm}
                        onChange={(e) =>
                            handlePasswordChange("confirm", e.target.value)
                        }
                    />
                    <ButtonTextWithLoading
                        variant="contained"
                        buttonStyle={{
                            alignSelf: "flex-end",
                        }}
                        isLoading={isPasswordSubmitting}
                        onClick={handleChangePassword}
                        text={"Next"}
                    />
                </Stack>
            </Stack>
        </Box>
    );
}
