import { CircularProgress, MenuItem, Select, Stack } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import {
    getCompanies,
    selectCompanies,
    selectCompany,
    setCurrCompany,
} from "./CompanyDropdown.reducer";

export default function CompanyDropdown() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companies = useSelector(selectCompanies);
    const currCompany = useSelector(selectCompany);
    const isCompaniesLoading = useSelector(checkIfLoading(getCompanies.type));

    const handleChange = (e) => {
        const company = companies.find((item) => item._id === e.target.value);
        dispatch(setCurrCompany(company));
        navigate("/overview");
    };

    useEffect(() => {
        dispatch(getCompanies({ action: getCompanies.type }));
    }, []);

    if (isCompaniesLoading) {
        return (
            <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"center"}
                border={"1px solid #ccc"}
                width={"100%"}
                borderRadius={1}
                padding={"0.5rem 1rem"}
            >
                <CircularProgress size={20} />
            </Stack>
        );
    }
    return (
        <Select
            size="small"
            fullWidth
            value={currCompany._id}
            onChange={handleChange}
        >
            {companies.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                    {item.name}
                </MenuItem>
            ))}
        </Select>
    );
}
