import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import RoleStages from "./RoleStages";
import CriteriaTable from "./CriteriaTable";
import SupportingDocuments from "../common/SupportingDocuments";

import {
    postSupport,
    selectUser,
} from "../../pages/loginPage/LoginPage.reducer";
import { editJob, deleteJob } from "../AddRole/AddRole.reducer";
import { setStage, stageInitialState } from "./editRole.slice";

const roleStages = [
    {
        key: "stage1",
        label: "Stage 1",
    },
    {
        key: "stage2",
        label: "Stage 2",
    },
    {
        key: "stage3",
        label: "Stage 3",
    },
];

export default function EditRole({ role }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    const [openRoleModal, setOpenRoleModal] = useState(false);
    const [roleDetails, setRoleDetails] = useState({
        "Vetting Criteria": "",
        "Role Title": "",
        "Role Deletion": "",
        "Role Status": "",
    });
    const [jd, setJd] = useState("");
    const [supportingDocuments, setSupportingDocuments] = useState([]);
    const [criteria, setCriteria] = useState([]);

    const showRoleModal = () => setOpenRoleModal(true);
    const closeRoleModal = () => setOpenRoleModal(false);

    const handleSubmit = () => {
        closeRoleModal();
        if (user.role === "ADMIN") {
            dispatch(
                editJob({
                    action: editJob.type,
                    id: role._id,
                    role: roleDetails["Role Title"],
                    status: roleDetails["Role Status"],
                    filters: criteria,
                    company: role.company,
                    jd,
                    supportingDocuments,
                })
            );
            return;
        }
        const combinedQuery = Object.keys(roleDetails)
            .map((item) => `${item}: ${roleDetails[item]}`)
            .join("\n");

        dispatch(
            postSupport({
                action: postSupport.type,
                query: combinedQuery,
                jd,
            })
        );
    };

    const handleDeleteRole = () => {
        dispatch(
            deleteJob({
                action: deleteJob.type,
                id: role._id,
                navigate,
            })
        );
    };

    const GetHelperText = () => {
        switch (roleDetails["Role Status"]) {
            case "ACTIVE": {
                return "Role is active and HireQuotient has currently allocated capacity to serve this role.";
            }
            case "CLOSED": {
                return "Role is closed. HireQuotient has either delivered the requisite number of profiles, or the role has been closed by the client.";
            }
            case "ON_HOLD": {
                return "Role is on hold. This is not being served actively by HireQuotient.";
            }
            default: {
                return "Role is active and HireQuotient has currently allocated capacity to serve this role.";
            }
        }
    };

    useEffect(() => {
        const {
            stage1 = stageInitialState,
            stage2 = stageInitialState,
            stage3 = stageInitialState,
        } = role;
        const stages = { stage1, stage2, stage3 };

        Object.entries(stages).forEach(([key, value]) => {
            dispatch(setStage({ key, value }));
        });

        const combinedFilters = role?.filters
            .map((item) => item.text)
            .join("\n");

        setRoleDetails({
            "Vetting Criteria": combinedFilters ?? "",
            "Role Title": role?.role ?? "",
            "Role Deletion": "NO",
            "Role Status": role?.status ?? "ACTIVE",
        });
        setCriteria(role?.filters);
    }, [role]);

    return (
        <>
            {roleDetails["Role Status"] === "PENDING_APPROVAL" &&
            user.role === "CLIENT" ? (
                <Typography variant="subtitle1" color="red">
                    (This role is yet to be approved by HQ)
                </Typography>
            ) : (
                <Tooltip title="Edit Role">
                    <IconButton onClick={showRoleModal}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )}
            <Dialog
                open={openRoleModal}
                onClose={closeRoleModal}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle>Edit Role</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={1}>
                        {user.role === "CLIENT" ? (
                            <TextField
                                size="small"
                                label="Vetting Criteria"
                                multiline
                                value={roleDetails["Vetting Criteria"]}
                                onChange={(e) => {
                                    setRoleDetails({
                                        ...roleDetails,
                                        "Vetting Criteria": e.target.value,
                                    });
                                }}
                            />
                        ) : (
                            <CriteriaTable
                                criteria={criteria}
                                setCriteria={setCriteria}
                            />
                        )}
                        <TextField
                            size="small"
                            type="file"
                            label="New/Revised JD"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setJd(e.target.files)}
                        />
                        <SupportingDocuments
                            handleSupportDocuments={(val) =>
                                setSupportingDocuments(val)
                            }
                        />
                        <TextField
                            size="small"
                            label="Role Title"
                            value={roleDetails["Role Title"]}
                            onChange={(e) => {
                                setRoleDetails({
                                    ...roleDetails,
                                    "Role Title": e.target.value,
                                });
                            }}
                        />
                        <TextField
                            select
                            size="small"
                            label="Change Role Status"
                            value={roleDetails["Role Status"]}
                            helperText={<GetHelperText />}
                            onChange={(e) => {
                                setRoleDetails({
                                    ...roleDetails,
                                    "Role Status": e.target.value,
                                });
                            }}
                        >
                            {user.role === "ADMIN" ? (
                                <>
                                    <MenuItem value={"PENDING_APPROVAL"}>
                                        Pending Approval
                                    </MenuItem>
                                    <MenuItem value={"ROLE_CHANGED"}>
                                        Role changed
                                    </MenuItem>
                                </>
                            ) : null}
                            <MenuItem value={"ACTIVE"}>Active</MenuItem>
                            <MenuItem value={"CLOSED"}>Closed</MenuItem>
                            <MenuItem value={"ON_HOLD"}>On hold</MenuItem>
                        </TextField>
                        {user.role === "CLIENT" ? (
                            <TextField
                                select
                                size="small"
                                label="Delete Role"
                                value={roleDetails["Role Deletion"]}
                                onChange={(e) => {
                                    setRoleDetails({
                                        ...roleDetails,
                                        "Role Deletion": e.target.value,
                                    });
                                }}
                            >
                                <MenuItem value={"YES"}>Yes</MenuItem>
                                <MenuItem value={"NO"}>No</MenuItem>
                            </TextField>
                        ) : null}
                        {user.role === "ADMIN"
                            ? roleStages.map((role) => (
                                  <RoleStages
                                      stageId={role.key}
                                      label={role.label}
                                  />
                              ))
                            : null}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {user.isSuperAdmin ? (
                        <Button
                            variant={"contained"}
                            onClick={handleDeleteRole}
                        >
                            Delete
                        </Button>
                    ) : null}
                    <Tooltip
                        title={
                            user.role === "ADMIN"
                                ? null
                                : "This will raise a new support ticket. HireQuotient typically responds in less than 12 hours."
                        }
                    >
                        <Button variant={"contained"} onClick={handleSubmit}>
                            {user.role === "ADMIN"
                                ? "Edit"
                                : "Send request to HireQuotient"}
                        </Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </>
    );
}
