import React, { useEffect, useState } from "react";
import { TextField, Grid, Typography, Stack, Box } from "@mui/material";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, signIn } from "./LoginPage.reducer";
import { get } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import backgroundImage from "../../assets/signinbg.jpg";
import hqLogo from "../../assets/hq-logo-onboard.svg";
import ButtonTextWithLoading from "../../components/CustomMUIComponents/ButtonTextWithLoading";

function LoginPage() {
    const user = useSelector(selectUser);
    const token = get(user, "uuid");
    const checkSigninLoading = useSelector(checkIfLoading(signIn.type));

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (!token) return;
        const from = location.state?.from || "/";
        navigate(from, { replace: true });
    }, [token]);

    const handleLogin = async (e) => {
        e.preventDefault();
        dispatch(
            signIn({
                email: email,
                password: password,
                action: signIn.type,
            })
        );
    };

    return (
        <Grid container style={{ minHeight: "100vh" }}>
            <Grid
                container
                item
                xs={12}
                sm={6}
                alignItems="center"
                direction="column"
                sx={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    padding: 2,
                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: "auto",
                        width: 180,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="hirequotient-logo"
                    src={hqLogo}
                />
                <Stack
                    my={"auto"}
                    fontSize={40}
                    fontWeight={700}
                    color={"#fff"}
                    textAlign={"center"}
                >
                    Effortlessly find and manage your talent with HireQuotient
                </Stack>
            </Grid>
            <Grid
                container
                item
                xs={12}
                sm={6}
                alignItems="center"
                direction="column"
                sx={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                }}
            >
                <Stack width={"100%"} my={"auto"}>
                    <Typography
                        sx={{
                            marginBottom: 8,
                            fontSize: 40,
                            fontWeight: 700,
                            opacity: 1,
                            color: "#479bd2",
                            textAlign: "center",
                        }}
                    >
                        Client Dashboard
                    </Typography>
                    <form onSubmit={handleLogin}>
                        <Stack width={"100%"} spacing={2}>
                            <TextField
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <PasswordInput
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <ButtonTextWithLoading
                                variant="contained"
                                type="submit"
                                isLoading={checkSigninLoading}
                                text="Log in"
                            />
                        </Stack>
                    </form>
                </Stack>
                <Stack>
                    <Typography>
                        © HireQuotient Pte Ltd. All rights reserved.
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default LoginPage;
