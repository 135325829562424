import { Stack, Typography } from "@mui/material";
import React from "react";
import WestIcon from "@mui/icons-material/West";

function HomePage() {
    return (
        <Stack
            padding={2}
            alignItems="center"
            justifyContent={"center"}
            flex={1}
        >
            <Typography variant="h4" fontWeight={700}>
                HireQuotient Sourcing Dashboard
            </Typography>
            <Stack
                direction={"row"}
                mt={2}
                p={2}
                borderRadius={2}
                bgcolor={"rgba(247,247,248)"}
                alignItems="center"
            >
                <WestIcon />
                <Typography variant="body1" fontWeight={500} ml={1}>
                    Please select an existing role or create a new one from the
                    side panel
                </Typography>
            </Stack>
        </Stack>
    );
}

export default HomePage;
