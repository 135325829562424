import { createSlice } from "@reduxjs/toolkit";

const initialState = Object.freeze({
    candidates: [],
    review: 0,
    accepted: 0,
    rejected: 0,
    approved: 0,
});

const reducers = {
    getCandidates(state, { payload }) {},
    setCandidates(state, { payload }) {
        state.candidates = payload;
        state.approved =
            payload.filter((item) => item.hqCandidateStatus === "APPROVED")
                .length || 0;
        state.review =
            payload.filter(
                (item) =>
                    item.hqCandidateStatus === "APPROVED" &&
                    item.status === "REVIEW"
            ).length || 0;
        state.accepted =
            payload.filter(
                (item) =>
                    item.hqCandidateStatus === "APPROVED" &&
                    item.status === "ACCEPTED"
            ).length || 0;
        state.rejected =
            payload.filter(
                (item) =>
                    item.hqCandidateStatus === "APPROVED" &&
                    item.status === "REJECTED"
            ).length || 0;
    },
    changeCandidateStatus(state, { payload }) {},
    addCandidates(state, { payload }) {},
    editCandidate(state, { payload }) {},
    deleteCandidate(state, { payload }) {},
    uploadCandidateCv(state, { payload }) {},
    bulkUpload(state, { payload }) {},
    addComments(state, { payload }) {},
    bulkUploadCVs(state, { payload }) {},
    bulkCleanCVs(state, { payload }) {},
    resetCandidates(state) {},
};

export const candidatesSlice = createSlice({
    name: "candidate",
    initialState,
    reducers,
});

export default candidatesSlice.reducer;

export const {
    getCandidates,
    setCandidates,
    changeCandidateStatus,
    resetCandidates,
    addCandidates,
    editCandidate,
    bulkUpload,
    uploadCandidateCv,
    deleteCandidate,
    addComments,
    bulkUploadCVs,
    bulkCleanCVs,
} = candidatesSlice.actions;

export const selectCandidates = (state) => state.candidate.candidates;
export const selectTotalReview = (state) => state.candidate.review;
export const selectTotalApproved = (state) => state.candidate.approved;
export const selectTotalAccepted = (state) => state.candidate.accepted;
export const selectTotalRejected = (state) => state.candidate.rejected;
