import { createSlice } from "@reduxjs/toolkit";

const initialState = Object.freeze({
    userList: [],
    companyList: [],
});

const reducers = {
    getUserList(state, { payload }) {},
    setUserList(state, { payload }) {
        state.userList = payload;
    },
    getCompanyList(state, { payload }) {},
    setCompanyList(state, { payload }) {
        state.companyList = payload;
    },
    addUser(state, { payload }) {},
    editUser(state, { payload }) {},
    deleteUser(state, { payload }) {},
    addCompany(state, { payload }) {},
    editCompany(state, { payload }) {},
    deleteCompany(state, { payload }) {},
    changePassword(state, { payload }) {},
    resetSuperAdmin(state) {},
};

export const superAdminSlice = createSlice({
    name: "superAdmin",
    initialState,
    reducers,
});

export default superAdminSlice.reducer;

export const {
    getUserList,
    setUserList,
    getCompanyList,
    setCompanyList,
    addUser,
    editUser,
    deleteUser,
    addCompany,
    editCompany,
    deleteCompany,
    changePassword,
    resetSuperAdmin,
} = superAdminSlice.actions;

export const selectUserList = (state) => state.superAdmin.userList;
export const selectCompanyList = (state) => state.superAdmin.companyList;
