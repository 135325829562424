import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    actions: [],
};

const loadersSlice = createSlice({
    name: "loaders",
    initialState,
    reducers: {
        startAction: (state, { payload }) => {
            state.actions = state.actions.concat(payload.action);
        },
        stopAction: (state, { payload }) => {
            state.actions = state.actions.filter((t) => t !== payload.action);
        },
    },
});

export const startAction = loadersSlice.actions.startAction;
export const stopAction = loadersSlice.actions.stopAction;
export const checkIfLoading = (type) => (state) =>
    state.loaders.actions.includes(type);

export default loadersSlice.reducer;
