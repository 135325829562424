import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    success: "",
    error: "",
    warning: "",
};

const reducers = {
    setSuccessNotification(state, { payload }) {
        state.success = payload;
        state.warning = "";
        state.error = "";
    },
    setErrorNotification(state, { payload }) {
        state.success = "";
        state.warning = "";
        state.error = payload;
    },
    setWarningNotification(state, { payload }) {
        state.success = "";
        state.error = "";
        state.warning = payload;
    },
    leaveRoutes(state) {},
};

export const reducersPage = createSlice({
    name: "notification",
    initialState,
    reducers,
});

export default reducersPage.reducer;

export const {
    setSuccessNotification,
    setErrorNotification,
    setWarningNotification,
    leaveRoutes,
} = reducersPage.actions;

export const selectSuccessNotification = (state) => state.notification.success;
export const selectErrorNotification = (state) => state.notification.error;
export const selectWarningNotification = (state) => state.notification.warning;
