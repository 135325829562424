import { Radio, TableCell } from "@mui/material";
import { useDispatch } from "react-redux";
import { editJdPoint } from "../AddRole/AddRole.reducer";

export default function RadioButton({ item }) {
    const dispatch = useDispatch();

    const handleChange = (e) => {
        dispatch(
            editJdPoint({
                id: item.id,
                type: e.target.value,
            })
        );
    };

    return (
        <>
            <TableCell>
                <Radio
                    checked={item.tag === "MUST"}
                    onChange={handleChange}
                    value="MUST"
                />
            </TableCell>
            <TableCell>
                <Radio
                    checked={item.tag === "GOOD"}
                    onChange={handleChange}
                    value="GOOD"
                />
            </TableCell>
        </>
    );
}
