import React, { useEffect, useMemo, useState } from "react";
import { isEmpty, isNil } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import ButtonTextWithLoading from "../../components/CustomMUIComponents/ButtonTextWithLoading";

import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectCompanyRoles } from "../../components/AddRole/AddRole.reducer";
import { setErrorNotification } from "../../components/Notification/Notification.reducer";
import {
    addRoleComment,
    deleteRoleComment,
    editRoleComment,
    getRoleComments,
    selectRoleComments,
} from "./RoleComments.reducer";
import dayjs from "dayjs";

function CommentModal({ open, onClose, commentObj, jobId }) {
    const dispatch = useDispatch();
    const isCommentSubmitting = useSelector(
        checkIfLoading(addRoleComment.type)
    );
    const isCommentEditing = useSelector(checkIfLoading(editRoleComment.type));

    const [comment, setComment] = useState("");

    useEffect(() => {
        if (!isEmpty(commentObj)) {
            setComment(commentObj.text);
        }
    }, [commentObj]);

    const handleAddComment = () => {
        if (!comment) {
            dispatch(setErrorNotification("Comment can't be empty"));
            return;
        }
        if (isEmpty(commentObj)) {
            dispatch(
                addRoleComment({
                    action: addRoleComment.type,
                    comment,
                    jobId,
                    onSuccess: closeModal,
                })
            );
        } else {
            dispatch(
                editRoleComment({
                    action: editRoleComment.type,
                    commentId: commentObj._id,
                    text: comment,
                    onSuccess: closeModal,
                })
            );
        }
    };

    const closeModal = () => {
        setComment("");
        onClose();
    };

    return (
        <Dialog open={open} onClose={closeModal} maxWidth="xs" fullWidth>
            <DialogTitle>
                {isEmpty(commentObj) ? "Add comment" : "Edit comment"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    label="Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <ButtonTextWithLoading
                    variant="contained"
                    text={"Submit"}
                    isLoading={isCommentSubmitting || isCommentEditing}
                    onClick={handleAddComment}
                />
            </DialogActions>
        </Dialog>
    );
}

function DeleteCommentModal({ open, onClose, commentId }) {
    const dispatch = useDispatch();
    const isCommentDeleting = useSelector(
        checkIfLoading(deleteRoleComment.type)
    );

    const handleDeleteComment = () => {
        dispatch(
            deleteRoleComment({
                action: deleteRoleComment.type,
                commentId,
                onSuccess: onClose,
            })
        );
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Are you sure you want to delete this comment?
            </DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ButtonTextWithLoading
                    variant="contained"
                    text={"Submit"}
                    isLoading={isCommentDeleting}
                    onClick={handleDeleteComment}
                />
            </DialogActions>
        </Dialog>
    );
}

const CenteredBox = React.memo(({ children }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            flex={1}
            bgcolor="#fff"
            borderRadius={2}
        >
            {children}
        </Stack>
    );
});

export default function RoleComments() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const roles = useSelector(selectCompanyRoles);
    const isCommentsLoading = useSelector(checkIfLoading(getRoleComments.type));
    const currentRole = useMemo(
        () => roles.find((item) => item._id === id),
        [roles, id]
    );

    const comments = useSelector(selectRoleComments);

    const [commentModal, setCommentModal] = useState({
        open: false,
        comment: {},
    });
    const [deleteCommentModal, setDeleteCommentModal] = useState({
        commentId: "",
        open: false,
    });

    const showModal = (comment) => {
        setCommentModal({
            comment: comment ?? {},
            open: true,
        });
    };

    const closeModal = () => {
        setCommentModal({
            comment: {},
            open: false,
        });
    };

    const showDeleteModal = (commentId) => {
        setDeleteCommentModal({
            commentId,
            open: true,
        });
    };

    const closeDeleteModal = () => {
        setDeleteCommentModal({
            commentId: "",
            open: false,
        });
    };

    useEffect(() => {
        if (!isNil(id)) {
            dispatch(
                getRoleComments({
                    action: getRoleComments.type,
                    jobId: id,
                })
            );
        }
    }, [id]);

    return (
        <>
            <Stack
                padding={2}
                flex={1}
                maxHeight="calc(100vh - 40px)"
                overflow="auto"
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                >
                    <Typography variant="subtitle1" fontWeight={700}>
                        {currentRole?.role} (Comments)
                    </Typography>
                    <Button variant="contained" onClick={() => showModal()}>
                        Add Comment
                    </Button>
                </Stack>
                {isCommentsLoading ? (
                    <CenteredBox>
                        <CircularProgress />
                    </CenteredBox>
                ) : comments?.length ? (
                    <List
                        sx={{
                            bgcolor: "#fff",
                            borderRadius: 2,
                            flex: 1,
                        }}
                    >
                        {comments.map((comment) => (
                            <>
                                <ListItem
                                    key={comment._id}
                                    sx={{
                                        bgcolor: !comment.isHQ
                                            ? "rgba(0, 0, 0, 0.04)"
                                            : "initial",
                                        marginBottom: 1,
                                    }}
                                    secondaryAction={
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            display={
                                                comment.isHQ ? "flex" : "none"
                                            }
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    showModal(comment)
                                                }
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    showDeleteModal(comment._id)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Stack>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            {comment.isHQ ? (
                                                <SupervisedUserCircleIcon />
                                            ) : (
                                                <AccountCircleIcon />
                                            )}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                color={"#000"}
                                                fontWeight={500}
                                            >
                                                {comment.text}
                                            </Typography>
                                        }
                                        secondary={dayjs(comment.updatedAt)
                                            .locale("en")
                                            .format("M/D/YYYY")}
                                    />
                                </ListItem>
                            </>
                        ))}
                    </List>
                ) : (
                    <CenteredBox>
                        <Typography color="#000">No comments found.</Typography>
                    </CenteredBox>
                )}
            </Stack>
            <CommentModal
                open={commentModal.open}
                onClose={closeModal}
                commentObj={commentModal.comment}
                jobId={id}
            />
            <DeleteCommentModal
                open={deleteCommentModal.open}
                onClose={closeDeleteModal}
                commentId={deleteCommentModal.commentId}
            />
        </>
    );
}
