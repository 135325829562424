import { get, isEmpty } from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setSuccessNotification } from "../../components/Notification/Notification.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { saveBinaryToFile } from "../../utils/helper";

import { CancelSagas } from "../../utils/saga.utils";
import {
    editCandidate,
    getCandidates,
    resetCandidates,
    setCandidates,
    addCandidates,
    changeCandidateStatus,
    bulkUpload,
    deleteCandidate,
    uploadCandidateCv,
    addComments,
    bulkUploadCVs,
    bulkCleanCVs,
} from "./CandidatePage.reducer";

function* getCandidatesSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().get,
            `/candidates/job/${payload.id}`
        );

        yield put(setCandidates(response));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* changeCandidateStatusSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().put,
            `/candidates/${payload.id}`,
            {
                status: payload.status,
                clientRemarks: payload.clientRemarks,
            }
        );

        const state = yield select();
        const candidatesStateValue = get(state, "candidate.candidates");

        const tmpCandidates = candidatesStateValue.map((item) => {
            if (item._id === payload.id) {
                return {
                    ...item,
                    status: payload.status,
                    clientRemarks: payload.clientRemarks,
                };
            }
            return item;
        });

        yield put(setCandidates(tmpCandidates));
        // yield put(setSuccessNotification(response.message));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addCandidatesSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const company = get(state, "company.currCompany");
        const candidates = get(state, "candidate.candidates");
        const cv =
            payload.file instanceof FileList ? payload.file[0] : payload.file;

        const response = yield call(
            new API().post,
            `/candidates`,
            {
                name: payload.name,
                email: payload.email,
                phoneNumber: payload.phoneNumber,
                hqRemarks: payload.hqRemarks,
                linkedIn: payload.linkedin,
                company: company._id,
                job: payload.job,
                cv,
            },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        if (!response) return;

        const tmpCandidates = [...candidates, response];
        yield put(setCandidates(tmpCandidates));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editCandidateSaga({ payload }) {
    try {
        const {
            action,
            name,
            email,
            phoneNumber,
            hqRemarks,
            linkedin,
            job,
            hqCandidateStatus,
            candidateId,
            file,
            documents,
        } = payload;

        yield put(startAction({ action }));

        const state = yield select();
        const company = get(state, "company.currCompany");
        const candidates = get(state, "candidate.candidates");
        const cv = file instanceof FileList ? file[0] : file;

        const candidatePayload = {
            name,
            email,
            phoneNumber,
            hqRemarks,
            linkedIn: linkedin,
            company: company._id,
            job,
            hqCandidateStatus,
        };

        const formData = new FormData();
        for (let key of Object.keys(candidatePayload)) {
            if (candidatePayload[key]) {
                formData.append(key, candidatePayload[key]);
            }
        }
        if (cv) formData.append("cv", cv);
        if (!isEmpty(documents)) {
            for (let i = 0; i < documents.length; i++) {
                formData.append("supportingDocuments", documents[i]);
            }
        }

        const response = yield call(
            new API().put,
            `/candidates/${candidateId}`,
            formData
        );
        if (!response) return;

        const tmpCandidates = candidates.map((item) => {
            if (item._id === candidateId) {
                return response;
            }
            return item;
        });
        yield put(setCandidates(tmpCandidates));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteCandidateSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const candidates = get(state, "candidate.candidates");
        const response = yield call(
            new API().delete,
            `/candidates/${payload.id}`
        );
        if (!response) return;

        const tmpCandidates = candidates.filter(
            (item) => item._id !== payload.id
        );
        yield put(setCandidates(tmpCandidates));
        yield put(setSuccessNotification("Candidate deleted successfully"));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* bulkUploadSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        const state = yield select();
        const company = get(state, "company.currCompany");
        const response = yield call(
            new API().post,
            `/candidates/bulk-upload`,
            {
                file: payload.file[0],
                company: company._id,
                job: payload.job,
            },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        if (!response) return;

        yield put(
            getCandidates({ action: getCandidates.type, id: payload.job })
        );
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* uploadCandidateCvSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const formData = new FormData();
        formData.append("job", payload.company);
        formData.append("cv", payload.cv);
        const response = yield call(
            new API().post,
            "/candidates/gpt",
            formData
        );
        payload.onSuccess(response);
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addCommentsSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(
            new API().put,
            `/candidates/${payload.id}`,
            {
                comment: payload.comment,
            }
        );

        const state = yield select();
        const candidates = get(state, "candidate.candidates");
        const tmpCandidates = candidates.map((item) => {
            if (item._id === payload.id) {
                return response;
            }
            return item;
        });
        yield put(setCandidates(tmpCandidates));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* bulkUploadCVsSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const formData = new FormData();
        formData.append("job", payload.job);
        formData.append("company", payload.company);
        for (let i = 0; i < payload.cv.length; i++) {
            formData.append(`files`, payload.cv[i]);
        }
        const response = yield call(
            new API().post,
            "/candidates/bulk-resume-upload",
            formData
        );
        if (!response) return;

        payload.onSuccess();

        const state = yield select();
        const candidatesStateValue = get(state, "candidate.candidates");
        yield put(
            setCandidates([
                ...candidatesStateValue,
                ...response.data.newCandidates,
            ])
        );
        yield put(setSuccessNotification(response.message));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* bulkCleanCVsSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const formData = new FormData();
        for (let i = 0; i < payload.cv.length; i++) {
            formData.append(`files`, payload.cv[i]);
        }
        const response = yield call(
            new API().post,
            "/candidates/bulk-clean-resume",
            formData,
            {
                responseType: "arraybuffer",
            }
        );
        if (!response) return;

        payload.onSuccess();
        saveBinaryToFile(response);
        yield put(setSuccessNotification(response.message));
    } catch (error) {
        console.error(error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        yield takeLatest(getCandidates.type, getCandidatesSaga),
        yield takeLatest(changeCandidateStatus.type, changeCandidateStatusSaga),
        yield takeLatest(addCandidates.type, addCandidatesSaga),
        yield takeLatest(editCandidate.type, editCandidateSaga),
        yield takeLatest(deleteCandidate.type, deleteCandidateSaga),
        yield takeLatest(uploadCandidateCv.type, uploadCandidateCvSaga),
        yield takeLatest(bulkUpload.type, bulkUploadSaga),
        yield takeLatest(addComments.type, addCommentsSaga),
        yield takeLatest(bulkUploadCVs.type, bulkUploadCVsSaga),
        yield takeLatest(bulkCleanCVs.type, bulkCleanCVsSaga),
    ];
    yield takeLatest(resetCandidates.type, CancelSagas, tasks);
}
