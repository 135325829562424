import {
    Button,
    IconButton,
    Radio,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { generateUUID } from "../../utils/helper";

export default function CriteriaTable({ criteria, setCriteria }) {
    const handleAddPoint = () => {
        setCriteria((prev) => [
            ...prev,
            { id: generateUUID(), key: "CUSTOM", text: "", tag: "GOOD" },
        ]);
    };

    const handleDeletePoint = (id) => {
        setCriteria((prev) => prev.filter((item) => item.id !== id));
    };

    const handlePointInput = (id, val) => {
        setCriteria((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        text: val,
                    };
                }
                return item;
            })
        );
    };

    const handlePointTag = (id, val) => {
        setCriteria((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        tag: val,
                    };
                }
                return item;
            })
        );
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={"50%"}>
                                Vetting Criteria
                            </TableCell>
                            <TableCell width={"15%"}>Must Have</TableCell>
                            <TableCell width={"15%"}>Good To Have</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {criteria.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell component="th" scope="row">
                                    <Stack direction={"row"} spacing={2}>
                                        <IconButton
                                            color="error"
                                            onClick={() =>
                                                handleDeletePoint(item.id)
                                            }
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            value={item.text}
                                            label={item.key}
                                            onChange={(e) =>
                                                handlePointInput(
                                                    item.id,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Radio
                                        checked={item.tag === "MUST"}
                                        onChange={(e) =>
                                            handlePointTag(
                                                item.id,
                                                e.target.value
                                            )
                                        }
                                        value="MUST"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Radio
                                        checked={item.tag === "GOOD"}
                                        onChange={(e) =>
                                            handlePointTag(
                                                item.id,
                                                e.target.value
                                            )
                                        }
                                        value="GOOD"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                variant={"contained"}
                sx={{
                    borderRadius: "50%",
                    minWidth: 42,
                    height: 40,
                    padding: 0,
                    marginTop: 1,
                    marginBottom: 2,
                    width: "fit-content",
                }}
                onClick={handleAddPoint}
            >
                <AddIcon />
            </Button>
        </>
    );
}
