import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";

import hqLogo from "../../assets/hqLogo.png";
import {
    editCompanyLogo,
    selectUser,
} from "../../pages/loginPage/LoginPage.reducer";
import {
    acceptStyle,
    baseStyle,
    focusedStyle,
    rejectStyle,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

function LogoInput({ handleLogo }) {
    const onDrop = useCallback((acceptedFiles) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name.slice(-4), {
                type: file.type,
            });
        });
        handleLogo(files[0]);
    }, []);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
    } = useDropzone({
        accept: {
            "image/jpeg": [],
            "image/png": [],
        },
        onDrop,
    });

    const files = acceptedFiles.map((file) => (
        <Stack
            maxWidth={400}
            mt={2}
            key={file.path}
            direction={"row"}
            alignItems={"center"}
        >
            <DescriptionIcon />
            <Typography>{file.path}</Typography>
        </Stack>
    ));

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop logo here ...</p>
                ) : (
                    <p>Click to upload your logo or drag and drop</p>
                )}
            </div>
            {files}
        </>
    );
}

export default function UploadLogo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const [logo, setLogo] = useState("");
    const [openLogoModal, setOpenLogoModal] = useState(false);

    const showLogoModal = () => setOpenLogoModal(true);
    const closeLogoModal = () => {
        setLogo("");
        setOpenLogoModal(false);
    };

    const handleSubmit = () => {
        dispatch(
            editCompanyLogo({
                action: editCompanyLogo.type,
                logo,
                company: user.company,
            })
        );
        closeLogoModal();
    };

    if (isEmpty(user)) return null;
    return (
        <>
            <Box
                component="img"
                sx={{
                    cursor: "pointer",
                    height: 52,
                    width: "auto",
                    maxHeight: { xs: 72, md: 64 },
                }}
                alt="hirequotient-logo"
                src={
                    user?.companyDetail?.companyLogo
                        ? user.companyDetail.companyLogo
                        : hqLogo
                }
                onClick={() => navigate("/")}
            />
            {user.role === "CLIENT" ? (
                <IconButton
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 10,
                    }}
                    onClick={showLogoModal}
                >
                    <EditIcon sx={{ width: 14, height: 14 }} />
                </IconButton>
            ) : null}
            <Dialog open={openLogoModal} onClose={closeLogoModal}>
                <DialogTitle>Upload company logo</DialogTitle>
                <DialogContent>
                    <LogoInput handleLogo={(val) => setLogo(val)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeLogoModal}>Cancel</Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
