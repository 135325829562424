import {
    Button,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import {
    addPoint,
    deletePoint,
    editPoint,
    editStage,
    selectStage1,
    selectStage2,
    selectStage3,
} from "./editRole.slice";

function selectRoleStages(key) {
    switch (key) {
        case "stage1":
            return selectStage1;
        case "stage2":
            return selectStage2;
        case "stage3":
            return selectStage3;
        default:
            return selectStage1;
    }
}

export default function RoleStages({ stageId, label }) {
    const dispatch = useDispatch();
    const stage = useSelector(selectRoleStages(stageId));

    const handleAddPoint = () => {
        dispatch(
            addPoint({
                key: stageId,
            })
        );
    };

    const handleDeletePoint = (id) => {
        dispatch(deletePoint({ key: stageId, id }));
    };

    const handlePointInput = (id, val, type) => {
        dispatch(editPoint({ key: stageId, id, type, text: val }));
    };

    const handleStageInput = (val, type) => {
        dispatch(editStage({ key: stageId, type, text: val }));
    };

    return (
        <Stack>
            <Typography fontSize={16} mb={1.5}>
                {label}
            </Typography>
            <Stack spacing={2}>
                {["info", "summary", "date"].map((key) => (
                    <TextField
                        size="small"
                        fullWidth
                        type={key}
                        InputLabelProps={{
                            shrink: key === "date" ? true : undefined,
                        }}
                        value={stage[key]}
                        label={key.toUpperCase()}
                        onChange={(e) => handleStageInput(e.target.value, key)}
                    />
                ))}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Points</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stage.points.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row">
                                        <Stack direction={"row"} spacing={2}>
                                            <IconButton
                                                color="error"
                                                onClick={() =>
                                                    handleDeletePoint(item.id)
                                                }
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                            {["date", "text"].map((key) => (
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    type={key}
                                                    InputLabelProps={{
                                                        shrink:
                                                            key === "date"
                                                                ? true
                                                                : undefined,
                                                    }}
                                                    value={item[key]}
                                                    label={key.toUpperCase()}
                                                    onChange={(e) =>
                                                        handlePointInput(
                                                            item.id,
                                                            e.target.value,
                                                            key
                                                        )
                                                    }
                                                />
                                            ))}
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    variant={"contained"}
                    sx={{
                        borderRadius: "50%",
                        minWidth: 42,
                        height: 40,
                        padding: 0,
                        marginTop: 1,
                        marginBottom: 2,
                        width: "fit-content",
                    }}
                    onClick={handleAddPoint}
                >
                    <AddIcon />
                </Button>
            </Stack>
        </Stack>
    );
}
