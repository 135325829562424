import { createSlice } from "@reduxjs/toolkit";

const initialState = Object.freeze({
    comments: [],
});

const reducers = {
    getRoleComments(state, { payload }) {},
    setRoleComments(state, { payload }) {
        state.comments = payload;
    },
    addRoleComment(state, { payload }) {},
    editRoleComment(state, { payload }) {},
    deleteRoleComment(state, { payload }) {},
    resetRoleComments(state) {},
};

export const roleCommentsSlice = createSlice({
    name: "roleComments",
    initialState,
    reducers,
});

export default roleCommentsSlice.reducer;

export const {
    getRoleComments,
    addRoleComment,
    deleteRoleComment,
    editRoleComment,
    setRoleComments,
    resetRoleComments,
} = roleCommentsSlice.actions;

export const selectRoleComments = (state) => state.roleComments.comments;
