import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

import {
    acceptStyle,
    baseStyle,
    focusedStyle,
    rejectStyle,
} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { uploadCandidateCv } from "../../pages/candidatePage/CandidatePage.reducer";
import { setErrorNotification } from "../Notification/Notification.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import ButtonTextWithLoading from "../CustomMUIComponents/ButtonTextWithLoading";

function CVInput({ handleCv }) {
    const onDrop = useCallback((acceptedFiles) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleCv(files[0]);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
    } = useDropzone({ accept: { "application/pdf": [] }, onDrop });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop CV here ...</p>
                ) : (
                    <p>Click to upload your CV or drag and drop</p>
                )}
            </div>
        </>
    );
}

export default function UploadCandidateCv({
    role,
    open,
    handleClose,
    onSubmit,
}) {
    const dispatch = useDispatch();
    const isCvSubmitting = useSelector(checkIfLoading(uploadCandidateCv.type));
    const [cv, setCv] = useState("");

    const handleSubmit = () => {
        if (!cv) {
            dispatch(setErrorNotification("Please upload cv"));
            return;
        }
        dispatch(
            uploadCandidateCv({
                action: uploadCandidateCv.type,
                cv,
                company: role,
                onSuccess: (val) => {
                    onSubmit({
                        ...val,
                        fromGpt: true,
                    });
                    handleClose();
                },
            })
        );
    };

    const onClose = () => {
        if (isCvSubmitting) return;
        handleClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Upload Candidate CV</DialogTitle>
            <DialogContent>
                <CVInput handleCv={(val) => setCv(val)} />
                {cv ? (
                    <Stack mt={2} direction={"row"} alignItems={"center"}>
                        <DescriptionIcon />
                        <Typography>{cv.name}</Typography>
                    </Stack>
                ) : null}
            </DialogContent>
            <DialogActions>
                <ButtonTextWithLoading
                    variant="contained"
                    onClick={handleSubmit}
                    isLoading={isCvSubmitting}
                    text="Submit"
                />
            </DialogActions>
        </Dialog>
    );
}
