/* eslint-disable */
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
    setErrorNotification,
    setSuccessNotification,
} from "../../components/Notification/Notification.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import {
    authLogin,
    editCompanyLogo,
    editSupportTicket,
    getSupportTickets,
    logout,
    postSupport,
    resetLogin,
    setSupportTickets,
    setUser,
    signIn,
} from "./LoginPage.reducer";

import store from "../../store";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import { setLocalStorage } from "../../utils/helper";
import { auth } from "../../firebase";
import { get } from "lodash";

function* signInSaga({ payload }) {
    const auth = getAuth();
    yield put(startAction({ action: payload.action }));
    signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then((userCredential) => {
            const user = userCredential.user;
            store.dispatch(
                authLogin({
                    action: authLogin.type,
                })
            );
        })
        .catch((error) => {
            console.error({ code: error.code, message: error.message });
            let message;
            switch (error.code) {
                case "auth/invalid-email": {
                    message = "Please enter your email ID to login";
                    break;
                }
                case "auth/missing-password": {
                    message = "Please enter your password to login";
                    break;
                }
                case "auth/user-not-found": {
                    message =
                        "This email ID is not registered with HireQuotient. Please contact the HireQuotient team.";
                    break;
                }
                case "auth/wrong-password": {
                    message =
                        "Your password is incorrect. Kindly contact the HireQuotient team.";
                    break;
                }
            }
            store.dispatch(setErrorNotification(message));
        })
        .finally(() => {
            store.dispatch(stopAction({ action: payload.action }));
        });
}

function* authLoginSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().get, "/users/login");
        setLocalStorage({ key: "user", value: JSON.stringify(response) });
        yield put(setUser(response));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* signOutSaga() {
    signOut(auth)
        .then(() => {
            store.dispatch(setUser({}));
            localStorage.removeItem("user");
            window.location.href = "/login";
        })
        .catch((err) => console.error("error", err));
}

function* postSupportSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const formData = new FormData();
        formData.append("title", "Edit request");
        formData.append("description", payload.query);
        if (payload?.jd?.length) formData.append("resource", payload.jd[0]);

        yield call(new API().post, "/support/ticket", formData);

        yield put(
            setSuccessNotification(
                `Your ticked has been raised. We'll get back to you shortly.`
            )
        );
    } catch (err) {
        console.error("****postSupportSagaError=", err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editCompanyLogoSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const formData = new FormData();
        formData.append("companyLogo", payload.logo);

        const response = yield call(
            new API().put,
            `/company/${payload.company}`,
            formData
        );

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        yield put(
            setUser({
                ...userStateValue,
                companyDetail: {
                    ...userStateValue.company,
                    companyLogo: response.companyLogo,
                },
            })
        );
        yield put(setSuccessNotification("Logo updated successfully"));
    } catch (err) {
        console.error("****editCompanyLogoSagaError=", err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getSupportTicketsSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        let url = "/support/ticket";
        if (payload.company !== "All")
            url = `/support/ticket?company=${payload.company}`;

        const response = yield call(new API().get, url);

        yield put(setSupportTickets(response));
    } catch (err) {
        console.error("****getSupportTicketsSagaError=", err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editSupportTicketSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const reqPayload = {
            status: payload.status,
            sendEmail: payload.sendEmail,
        };
        if (payload.reply) reqPayload.reply = payload.reply;

        const response = yield call(
            new API().put,
            `/support/ticket/${payload.id}`,
            reqPayload
        );

        const state = yield select();
        const supportTicketsStateValue = get(state, "signin.supportTickets");
        const tmpSupportTickets = supportTicketsStateValue.map((item) => {
            if (item._id === payload.id) {
                return response;
            }
            return item;
        });
        yield put(setSupportTickets(tmpSupportTickets));
        yield put(setSuccessNotification("Ticket updated successfully"));
    } catch (err) {
        console.error("****editSupportTicketSagaError=", err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        yield takeLatest(signIn.type, signInSaga),
        yield takeLatest(logout.type, signOutSaga),
        yield takeEvery(authLogin.type, authLoginSaga),
        yield takeLatest(postSupport.type, postSupportSaga),
        yield takeLatest(editCompanyLogo.type, editCompanyLogoSaga),
        yield takeLatest(getSupportTickets.type, getSupportTicketsSaga),
        yield takeLatest(editSupportTicket.type, editSupportTicketSaga),
    ];
    yield takeLatest(resetLogin.type, CancelSagas, tasks);
}
