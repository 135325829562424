import { useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";

import JDPoints from "../JDPoints/JDPoints";
import { useDispatch, useSelector } from "react-redux";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { Skeleton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import {
    createJob,
    editJob,
    getAnalyzedJd,
    selectCurrRole,
    selectJdPoints,
} from "./AddRole.reducer";

import { useDropzone } from "react-dropzone";
import { setErrorNotification } from "../Notification/Notification.reducer";
import {
    acceptStyle,
    baseStyle,
    focusedStyle,
    rejectStyle,
} from "../../utils/helper";
import StyledIconButton from "../CustomMUIComponents/IconButton";
import SupportingDocuments from "../common/SupportingDocuments";

function JDInput({ handleJD }) {
    const onDrop = useCallback((acceptedFiles) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleJD(files[0]);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
    } = useDropzone({ accept: { "application/pdf": [] }, onDrop });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop JD here ...</p>
                ) : (
                    <p>
                        Click to upload your JD or drag and drop (PDF format
                        only)
                    </p>
                )}
            </div>
        </>
    );
}

export default function AddRole() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(0);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [jobDetails, setJobDetails] = useState({
        name: "",
        jd: "",
        supportingDocuments: [],
    });
    const currRole = useSelector(selectCurrRole);
    const jdPoints = useSelector(selectJdPoints);
    const isAnalyzingJD = useSelector(checkIfLoading(getAnalyzedJd.type));

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        if (view === 1 && isAnalyzingJD) return;
        setOpen(false);
        setJobDetails({
            name: "",
            jd: "",
            supportingDocuments: "",
        });
        setView(0);
    };

    const showConfirmationModal = () => {
        setOpenConfirmationModal(true);
    };

    const closeConfirmationModal = () => {
        setOpenConfirmationModal(false);
    };

    const handlePrev = () => {
        setView((prev) => prev - 1);
    };

    const handleAddRole = () => {
        dispatch(
            createJob({
                action: editJob.type,
                role: currRole.role,
                jd: currRole.jd,
                company: currRole.company,
                filters: jdPoints,
                description: currRole.description,
                supportingDocuments: currRole.supportingDocuments,
            })
        );
        closeConfirmationModal();
        handleClose();
    };

    const handleNext = () => {
        if (!jobDetails.name || !jobDetails.jd) {
            const message = !jobDetails.name
                ? "Please enter job title"
                : "Please upload job description";
            dispatch(setErrorNotification(message));
            return;
        }
        if (view === 1) {
            showConfirmationModal();
            return;
        }
        setView((prev) => prev + 1);
        dispatch(
            getAnalyzedJd({
                role: jobDetails.name,
                jd: jobDetails.jd,
                supportingDocuments: jobDetails.supportingDocuments,
                action: getAnalyzedJd.type,
            })
        );
    };

    return (
        <>
            <Tooltip title="Add Role">
                <StyledIconButton variant="outlined" onClick={handleClickOpen}>
                    <AddIcon fontSize="small" />
                </StyledIconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                on
                maxWidth={"lg"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {view === 0 ? "Add new role" : null}
                    {view === 1
                        ? isAnalyzingJD
                            ? "Analyzing your JD..."
                            : "Criteria checklist for vetting candidate CVs for this role"
                        : null}
                    {/* {view === 2 ? "Upload any other relevant documents" : null} */}
                </DialogTitle>
                <DialogContent>
                    {view === 1 ? (
                        <Typography
                            variant="subtitle2"
                            mb={2}
                            width={isAnalyzingJD ? 400 : 800}
                        >
                            {isAnalyzingJD
                                ? "Using our AI-powered intelligence to extract important vetting criteria from your Job Description document."
                                : "Please edit existing vetting criteria, or add new criteria. We recommended using 3-5 vetting criteria for candidate CVs based on job location, educational qualification, skills, and years of experience. Feel free to add other criteria relevant to this particular job role."}
                        </Typography>
                    ) : null}
                    {view === 0 ? (
                        <>
                            <TextField
                                size="small"
                                label="Job title"
                                sx={{
                                    width: "100%",
                                    marginTop: 1,
                                    marginBottom: 2,
                                }}
                                value={jobDetails.name}
                                onChange={(e) =>
                                    setJobDetails((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                    }))
                                }
                            />
                            <JDInput
                                handleJD={(val) =>
                                    setJobDetails((prev) => ({
                                        ...prev,
                                        jd: val,
                                    }))
                                }
                            />
                            {jobDetails.jd ? (
                                <Stack
                                    spacing={0.5}
                                    mt={2}
                                    direction={"row"}
                                    alignItems={"center"}
                                >
                                    <DescriptionIcon />
                                    <Typography>
                                        {jobDetails.jd.name}
                                    </Typography>
                                </Stack>
                            ) : null}
                            <SupportingDocuments
                                handleSupportDocuments={(val) =>
                                    setJobDetails((prev) => ({
                                        ...prev,
                                        supportingDocuments: val,
                                    }))
                                }
                            />
                        </>
                    ) : null}
                    {view === 1 ? (
                        isAnalyzingJD ? (
                            <Stack spacing={2}>
                                {[0, 0, 0].map((item, index) => (
                                    <Skeleton
                                        key={`${item}${index}`}
                                        variant="rectangular"
                                        width={400}
                                        height={60}
                                    />
                                ))}
                            </Stack>
                        ) : (
                            <JDPoints />
                        )
                    ) : null}
                </DialogContent>
                {!isAnalyzingJD ? (
                    <DialogActions>
                        {view !== 0 ? (
                            <Button onClick={handlePrev}>Previous</Button>
                        ) : null}
                        <Button variant="contained" onClick={handleNext}>
                            {view === 1 ? "Activate Role" : "Next"}
                        </Button>
                    </DialogActions>
                ) : null}
                <Dialog
                    open={openConfirmationModal}
                    onClose={closeConfirmationModal}
                    maxWidth={"md"}
                >
                    <DialogTitle>
                        This will activate your role for HireQuotient. Future
                        changes in role details will require alignment with
                        HireQuotient. You may want to review the vetting
                        criteria once more before activating your role.
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={closeConfirmationModal}>
                            I want to review the details once more.
                        </Button>
                        <Button variant="contained" onClick={handleAddRole}>
                            I am sure and I wish to activate this role.
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        </>
    );
}
