import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Stack, TextField } from "@mui/material";
import { useParams } from "react-router-dom";

import SupportingDocuments from "../common/SupportingDocuments";

import {
    addCandidates,
    deleteCandidate,
    editCandidate,
} from "../../pages/candidatePage/CandidatePage.reducer";

import style from "./CandidateTable.module.css";

const AddCandidate = (props) => {
    const { open, handleClose, name: editName, _id, fromGpt } = props;

    const dispatch = useDispatch();
    const param = useParams();

    const [name, setName] = useState(props.name || "");
    const [email, setEmail] = useState(props.email || "");
    const [phone, setPhone] = useState(props.phoneNumber || "");
    const [linkedin, setLinkedin] = useState(props.linkedIn || "");
    const [hqRemarks, setHqRemark] = useState(props.hqRemarks || "");
    const [file, setFile] = useState(props.cv || "");
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        if (!editName) return;
        setName(props.name);
        setEmail(props.email);
        setPhone(props.phoneNumber);
        setLinkedin(props.linkedIn);
        setHqRemark(props.hqRemarks);
        setFile(props.cv);
    }, [editName]);

    const onAddCandidate = (e) => {
        e.preventDefault();
        if (!name) return;
        dispatch(
            addCandidates({
                name,
                email,
                phoneNumber: phone,
                linkedin,
                hqRemarks,
                job: param.id,
                file,
            })
        );
        props.handleClose();
    };

    const onEditCandidate = (e) => {
        e.preventDefault();
        if (!name) return;
        dispatch(
            editCandidate({
                name,
                email,
                phoneNumber: phone,
                linkedin,
                hqRemarks,
                job: param.id,
                file,
                candidateId: _id,
                documents,
            })
        );
        props.handleClose();
    };

    const onDeleteCandidate = () => {
        dispatch(
            deleteCandidate({
                action: deleteCandidate.type,
                id: _id,
            })
        );
        props.handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
            <DialogTitle>
                {fromGpt ? "Add Candidate" : "Edit Candidate"}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={!fromGpt ? onEditCandidate : onAddCandidate}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        required
                        onChange={(e) => setName(e.target.value)}
                        className={style.inputAddCandidate}
                        value={name}
                        sx={{ marginTop: 2 }}
                    />
                    <br />
                    <br />
                    <TextField
                        label="Email"
                        variant="outlined"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className={style.inputAddCandidate}
                        value={email}
                    />
                    <br />
                    <br />
                    <TextField
                        label="Phone number"
                        variant="outlined"
                        type="tel"
                        onChange={(e) => setPhone(e.target.value)}
                        className={style.inputAddCandidate}
                        value={phone}
                    />
                    <br />
                    <br />
                    <TextField
                        label="Linkedin profile"
                        variant="outlined"
                        type="url"
                        onChange={(e) => setLinkedin(e.target.value)}
                        className={style.inputAddCandidate}
                        value={linkedin}
                    />
                    <br />
                    <br />
                    <TextField
                        label="HQ remark"
                        variant="outlined"
                        type="text"
                        onChange={(e) => setHqRemark(e.target.value)}
                        className={style.inputAddCandidate}
                        value={hqRemarks}
                        sx={{ marginBottom: 2 }}
                    />
                    <Stack>
                        <a
                            href={file}
                            rel="noreferrer"
                            target="_blank"
                            style={{
                                marginBottom: 16,
                                display: "inline-block",
                                width: "fit-content",
                            }}
                        >
                            Current Resume
                        </a>
                    </Stack>
                    <TextField
                        variant="outlined"
                        type="file"
                        // required={!editName}
                        onChange={(e) => setFile(e.target.files)}
                        sx={{ width: "100%" }}
                    />
                    <br />
                    <SupportingDocuments
                        handleSupportDocuments={(val) => {
                            setDocuments(val);
                        }}
                    />
                    <br />
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                        spacing={2}
                    >
                        {!fromGpt ? (
                            <Button
                                variant="contained"
                                onClick={onDeleteCandidate}
                            >
                                Delete
                            </Button>
                        ) : null}
                        <Button
                            variant="contained"
                            type="submit"
                            className={style.addCandidateSubmit}
                        >
                            Next
                        </Button>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddCandidate;
