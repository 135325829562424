import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, TextField, Typography } from "@mui/material";
import style from "./CandidateTable.module.css";
import { bulkUpload } from "../../pages/candidatePage/CandidatePage.reducer";

const BulkUpload = (props) => {
    const dispatch = useDispatch();
    const param = useParams();
    const [file, setFile] = useState(props.cv || "");
    const { open, handleClose, name: editName } = props;

    const onBulkUpload = (e) => {
        e.preventDefault();
        if (!file) return;

        dispatch(
            bulkUpload({
                job: param.id,
                file,
            })
        );
        props.handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
            <DialogTitle>Bulk upload candidates</DialogTitle>
            <DialogContent>
                <form onSubmit={onBulkUpload}>
                    <a
                        href="https://hq-service.s3.ap-southeast-1.amazonaws.com/sample.csv"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Sample csv
                    </a>
                    <br />
                    <br />
                    <TextField
                        variant="outlined"
                        type="file"
                        required={!editName}
                        onChange={(e) => setFile(e.target.files)}
                    />
                    <br />
                    <br />
                    <Button
                        variant="contained"
                        type="submit"
                        className={style.addCandidateSubmit}
                    >
                        Next
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default BulkUpload;
