import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";

import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import {
    addCompany,
    deleteCompany,
    editCompany,
    getCompanyList,
    getUserList,
    selectCompanyList,
    selectUserList,
} from "../userListPage/UserListPage.reducer";
import hqLogo from "../../assets/hqLogo.png";
import { setCurrCompany } from "../../components/CompanyDropdown/CompanyDropdown.reducer";
import { useNavigate } from "react-router-dom";

const AddCompany = ({ edit, open, handleClose }) => {
    const dispatch = useDispatch();
    const userList = useSelector(selectUserList);
    const csmUsers = isEmpty(userList)
        ? []
        : userList.filter((user) => user.roleType === "CSM");
    const recruiterUsers = isEmpty(userList)
        ? []
        : userList.filter((user) => user.roleType === "RECRUITER");

    const [openModal, setOpenModal] = useState(false);
    const [company, setCompany] = useState({
        name: "",
        logo: "",
        dOFPayment: dayjs().format("YYYY-MM-DD"),
        dOLPayment: dayjs().format("YYYY-MM-DD"),
        promise: "",
        location: "",
        status: "ACTIVE",
        csm: "",
        recruiter: "",
        footer: "",
    });

    useEffect(() => {
        dispatch(
            getUserList({
                action: getUserList.type,
            })
        );
    }, []);

    useEffect(() => {
        if (isEmpty(edit)) return;

        setCompany({
            name: edit.name,
            logo: edit.companyLogo,
            dOFPayment: dayjs(edit.dOFPayment).format("YYYY-MM-DD"),
            dOLPayment: edit.dOLPayment
                ? dayjs(edit.dOLPayment).format("YYYY-MM-DD")
                : "",
            promise: edit.promise,
            location: edit.location,
            status: edit.status,
            csm: edit.csm,
            recruiter: edit.recruiter,
            footer: edit.showFooter,
        });
        setOpenModal(open);
    }, [edit, open]);

    const showModal = () => setOpenModal(true);
    const closeModal = () => {
        if (handleClose) handleClose();
        setOpenModal(false);
        setCompany({
            name: "",
            logo: "",
            dOFPayment: dayjs().format("YYYY-MM-DD"),
            dOLPayment: dayjs().format("YYYY-MM-DD"),
            promise: "",
            location: "",
            status: "ACTIVE",
            csm: "",
            recruiter: "",
            footer: "",
        });
    };

    const handleSubmit = () => {
        closeModal();
        if (edit?._id) {
            dispatch(
                editCompany({
                    action: editCompany.type,
                    id: edit._id,
                    name: company.name,
                    logo: company.logo,
                    dOFPayment: company.dOFPayment,
                    dOLPayment: company.dOLPayment,
                    promise: company.promise,
                    location: company.location,
                    status: company.status,
                    csm: company.csm,
                    recruiter: company.recruiter,
                    footer: company.footer,
                })
            );
            return;
        }
        dispatch(
            addCompany({
                action: addCompany.type,
                name: company.name,
                logo: company.logo,
                dOFPayment: company.dOFPayment,
                promise: company.promise,
                location: company.location,
                status: company.status,
                csm: company.csm,
                recruiter: company.recruiter,
                footer: company.footer,
            })
        );
    };

    const handleDelete = () => {
        dispatch(
            deleteCompany({
                action: deleteCompany.type,
                id: edit._id,
            })
        );
        closeModal();
    };

    return (
        <>
            <Stack direction="row" justifyContent="flex-end" mb={2}>
                <Button variant="contained" onClick={showModal}>
                    Add Company
                </Button>
            </Stack>
            <Dialog
                open={openModal}
                onClose={closeModal}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    {edit?._id ? "Edit Company" : "Add Company"}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={1}>
                        <TextField
                            size="small"
                            label="Name"
                            value={company.name}
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    name: e.target.value,
                                });
                            }}
                        />
                        {edit?._id && edit.companyLogo ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography>Existing Logo:</Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        cursor: "pointer",
                                        height: 52,
                                        width: "auto",
                                        maxHeight: { xs: 72, md: 64 },
                                    }}
                                    alt="hirequotient-logo"
                                    src={
                                        edit.companyLogo
                                            ? edit.companyLogo
                                            : hqLogo
                                    }
                                />
                            </Stack>
                        ) : null}
                        <TextField
                            size="small"
                            label="Logo"
                            type="file"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    logo: e.target.files,
                                });
                            }}
                        />
                        <TextField
                            size="small"
                            label="Date of first payment"
                            type="date"
                            value={company.dOFPayment}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) =>
                                setCompany({
                                    ...company,
                                    dOFPayment: e.target.value,
                                })
                            }
                        />
                        {edit._id ? (
                            <TextField
                                size="small"
                                label="Date of last payment"
                                type="date"
                                value={company.dOLPayment}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) =>
                                    setCompany({
                                        ...company,
                                        dOLPayment: e.target.value,
                                    })
                                }
                            />
                        ) : null}
                        <TextField
                            size="small"
                            label="Delivery promise details"
                            multiline
                            rows={3}
                            value={company.promise}
                            onChange={(e) =>
                                setCompany({
                                    ...company,
                                    promise: e.target.value,
                                })
                            }
                        />
                        <TextField
                            size="small"
                            label="Location"
                            value={company.location}
                            onChange={(e) =>
                                setCompany({
                                    ...company,
                                    location: e.target.value,
                                })
                            }
                        />
                        <TextField
                            size="small"
                            select
                            label="Status"
                            value={company.status}
                            onChange={(e) =>
                                setCompany({
                                    ...company,
                                    status: e.target.value,
                                })
                            }
                        >
                            <MenuItem value="ACTIVE">Active</MenuItem>
                            <MenuItem value="CHURNED">Churned</MenuItem>
                            <MenuItem value="PAUSED">Paused</MenuItem>
                        </TextField>
                        <TextField
                            size="small"
                            select
                            label="Assign CSM"
                            value={company.csm}
                            onChange={(e) =>
                                setCompany({
                                    ...company,
                                    csm: e.target.value,
                                })
                            }
                        >
                            {isEmpty(csmUsers) ? (
                                <MenuItem disabled sx={{ color: "#000" }}>
                                    No CSM users found.
                                </MenuItem>
                            ) : (
                                csmUsers.map((user) => (
                                    <MenuItem value={user._id}>
                                        {user.name}
                                    </MenuItem>
                                ))
                            )}
                        </TextField>
                        <TextField
                            size="small"
                            select
                            label="Assign recruiter"
                            value={company.recruiter}
                            onChange={(e) =>
                                setCompany({
                                    ...company,
                                    recruiter: e.target.value,
                                })
                            }
                        >
                            {isEmpty(recruiterUsers) ? (
                                <MenuItem disabled sx={{ color: "#000" }}>
                                    No recruiter users found.
                                </MenuItem>
                            ) : (
                                recruiterUsers.map((user) => (
                                    <MenuItem value={user._id}>
                                        {user.name}
                                    </MenuItem>
                                ))
                            )}
                        </TextField>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography>Show Footer</Typography>
                            <Switch
                                checked={company.footer || false}
                                onChange={(e) =>
                                    setCompany({
                                        ...company,
                                        footer: e.target.checked,
                                    })
                                }
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {edit?._id && (
                        <Button variant="contained" onClick={handleDelete}>
                            Delete
                        </Button>
                    )}
                    <Button variant="contained" onClick={handleSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default function CompanyListPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyList = useSelector(selectCompanyList);
    const isCompaniesLoading = useSelector(checkIfLoading(getCompanyList.type));

    const [editCompanyModal, setEditCompanyModal] = useState({
        open: false,
        company: {},
    });

    useEffect(() => {
        dispatch(
            getCompanyList({
                action: getCompanyList.type,
            })
        );
    }, []);

    const handleClose = () => {
        setEditCompanyModal({
            open: false,
            company: {},
        });
    };

    const columns = [
        { field: "name", headerName: "Name", flex: 1 },
        {
            field: "createdAt",
            headerName: "Created On",
            flex: 1,
            valueGetter: (params) =>
                dayjs(params.row.createdAt).format("MM/DD/YYYY"),
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Edit company">
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditCompanyModal({
                                        open: true,
                                        company: params.row,
                                    });
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    const handleRowClick = (company) => {
        dispatch(setCurrCompany(company.row));
        navigate("/overview");
    };

    return (
        <Stack p={2} flex={1}>
            <AddCompany
                open={editCompanyModal.open}
                edit={editCompanyModal.company}
                handleClose={handleClose}
            />
            <Box sx={{ height: "calc(100vh - 82px)", width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={companyList}
                    columns={columns}
                    loading={isCompaniesLoading}
                    hideFooter
                    onRowClick={handleRowClick}
                />
            </Box>
        </Stack>
    );
}
