import { get, isEmpty } from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import {
    getCompanies,
    leaveCompany,
    setCompanies,
    setCurrCompany,
} from "./CompanyDropdown.reducer";

function* getCompaniesSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        const state = yield select();
        const currCompanyStateValue = get(state, "company.currCompany");

        const response = yield call(new API().get, "/company");

        if(!response) return;

        yield put(setCompanies(response));
        if (isEmpty(currCompanyStateValue))
            yield put(setCurrCompany(response[0]));
    } catch (error) {
        console.error("***getCompaniesSagaError=", error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [yield takeLatest(getCompanies.type, getCompaniesSaga)];
    yield takeLatest(leaveCompany.type, CancelSagas, tasks);
}
