import { useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { addComments } from "../../pages/candidatePage/CandidatePage.reducer";

export default function AddComments({ id, name }) {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [comment, setComment] = useState("");
    const [err, setErr] = useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setErr(false);
        setComment("");
        setOpenModal(false);
    };

    const handleSubmit = () => {
        if (!comment) {
            setErr(true);
            return;
        }
        dispatch(
            addComments({
                action: addComments.type,
                id,
                comment,
            })
        );
        handleCloseModal();
    };

    return (
        <>
            <Stack direction="row" alignItems="flex-start">
                <Tooltip title="Add comments">
                    <IconButton sx={{ padding: '4px' }} onClick={handleOpenModal}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Adding comment on {name}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        hiddenLabel
                        multiline
                        variant="filled"
                        rows={4}
                        placeholder="Type in your comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        error={err}
                        helperText={err ? "*Required" : null}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
