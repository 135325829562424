import dayjs from "dayjs";
import { get, isEmpty, isString } from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { setSuccessNotification } from "../../components/Notification/Notification.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";

import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import { setUser } from "../loginPage/LoginPage.reducer";
import {
    addCompany,
    addUser,
    changePassword,
    deleteCompany,
    deleteUser,
    editCompany,
    editUser,
    getCompanyList,
    getUserList,
    resetSuperAdmin,
    setCompanyList,
    setUserList,
} from "./UserListPage.reducer";

function* getUserListSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().get, "/users");
        yield put(setUserList(response));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getCompanyListSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().get, "/company");
        yield put(setCompanyList(response));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addUserSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(new API().post, "/users", payload.user);
        if (!response?._id) return;

        const state = yield select();
        const userListStateValue = get(state, "superAdmin.userList");
        yield put(setUserList([...userListStateValue, response]));
        yield put(setSuccessNotification("User added successfully"));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addCompanySaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const formData = new FormData();
        formData.append("name", payload.name);
        if (payload.logo) {
            const companyLogo = isString(payload.logo)
                ? payload.logo
                : payload.logo[0];
            formData.append("companyLogo", companyLogo);
        }
        formData.append(
            "DOFirstPayment",
            dayjs(payload.dOFPayment).toISOString()
        );
        if (payload.promise) formData.append("promise", payload.promise);
        if (payload.location) formData.append("location", payload.location);
        if (payload.status) formData.append("status", payload.status);
        if (payload.csm) formData.append("csm", payload.csm);
        if (payload.recruiter) formData.append("recruiter", payload.recruiter);
        formData.append("showFooter", payload.footer);

        const response = yield call(new API().post, "/company", formData);
        if (!response?._id) return;

        const state = yield select();
        const companyListStateValue = get(state, "superAdmin.companyList");
        yield put(setCompanyList([...companyListStateValue, response]));
        yield put(setSuccessNotification("Company added successfully"));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editUserSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        const response = yield call(
            new API().put,
            `/users/${payload.id}`,
            payload.user
        );

        const state = yield select();
        const userListStateValue = get(state, "superAdmin.userList");

        const tmpUsers = userListStateValue.map((item) => {
            if (item._id === payload.id) {
                return response;
            }
            return item;
        });
        yield put(setUserList(tmpUsers));
        yield put(setSuccessNotification("User updated successfully"));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteUserSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        yield call(new API().delete, `/users/${payload.id}`);

        const state = yield select();
        const userListStateValue = get(state, "superAdmin.userList");
        const tmpUsers = userListStateValue.filter(
            (user) => user._id !== payload.id
        );
        yield put(setUserList(tmpUsers));
        yield put(setSuccessNotification("User deleted successfully"));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editCompanySaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const formData = new FormData();
        formData.append("name", payload.name);
        if (payload.logo) {
            const companyLogo = isString(payload.logo)
                ? payload.logo
                : payload.logo[0];
            formData.append("companyLogo", companyLogo);
        }
        formData.append("dOFPayment", dayjs(payload.dOFPayment).toISOString());
        if (payload.dOLPayment) {
            formData.append(
                "dOLPayment",
                dayjs(payload.dOLPayment).toISOString()
            );
        }
        if (payload.promise) formData.append("promise", payload.promise);
        if (payload.location) formData.append("location", payload.location);
        if (payload.csm) formData.append("csm", payload.csm);
        if (payload.recruiter) formData.append("recruiter", payload.recruiter);
        formData.append("showFooter", payload.footer);

        const response = yield call(
            new API().put,
            `/company/${payload.id}`,
            formData
        );

        const state = yield select();
        const companyListStateValue = get(state, "superAdmin.companyList");

        const tmpCompanies = companyListStateValue.map((item) => {
            if (item._id === payload.id) {
                return response;
            }
            return item;
        });
        yield put(setCompanyList(tmpCompanies));
        yield put(setSuccessNotification("Company updated successfully"));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteCompanySaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        yield call(new API().delete, `/company/${payload.id}`);

        const state = yield select();
        const companyListStateValue = get(state, "superAdmin.companyList");
        const tmpUsers = companyListStateValue.filter(
            (user) => user._id !== payload.id
        );
        yield put(setCompanyList(tmpUsers));
        yield put(setSuccessNotification("Company deleted successfully"));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* changePasswordSaga({ payload }) {
    try {
        const { action, password, navigate } = payload;

        yield put(startAction({ action }));

        const state = yield select();
        const userStateValue = get(state, "signin.user");

        const response = yield call(
            new API().post,
            `/users/change-password/${userStateValue._id}`,
            {
                password,
            }
        );
        if (isEmpty(response)) return;

        navigate("/login");
        yield put(setUser(response.data));
        yield put(
            setSuccessNotification("Password changed successfully. Login again")
        );
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        yield takeLatest(getUserList.type, getUserListSaga),
        yield takeLatest(getCompanyList.type, getCompanyListSaga),
        yield takeLatest(addUser.type, addUserSaga),
        yield takeLatest(editUser.type, editUserSaga),
        yield takeLatest(deleteUser.type, deleteUserSaga),
        yield takeLatest(addCompany.type, addCompanySaga),
        yield takeLatest(editCompany.type, editCompanySaga),
        yield takeLatest(deleteCompany.type, deleteCompanySaga),
        yield takeLatest(changePassword.type, changePasswordSaga),
    ];
    yield takeLatest(resetSuperAdmin.type, CancelSagas, tasks);
}
