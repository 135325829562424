import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#479bd2",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: 600,
    },
}));

export default StyledTableCell;
