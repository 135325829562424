import { createSlice } from "@reduxjs/toolkit";

const initialState = Object.freeze({
    authenticated: false,
    user: {},
    supportTickets: [],
});

const reducers = {
    setUser(state, { payload }) {
        state.user = payload;
    },
    setAuthenticated(state, { payload }) {
        state.authenticated = payload;
    },
    signIn(state, { payload }) {},
    logout(state, { payload }) {},
    authLogin(state, { payload }) {},
    postSupport(state, { payload }) {},
    editCompanyLogo(state, { payload }) {},
    getSupportTickets(state, { payload }) {},
    setSupportTickets(state, { payload }) {
        state.supportTickets = payload;
    },
    editSupportTicket(state, { payload }) {},
    resetLogin(state) {},
};

export const signinSlice = createSlice({
    name: "signin",
    initialState,
    reducers,
});

export default signinSlice.reducer;

export const {
    setUser,
    setAuthenticated,
    signIn,
    logout,
    authLogin,
    resetLogin,
    postSupport,
    editCompanyLogo,
    getSupportTickets,
    setSupportTickets,
    editSupportTicket,
} = signinSlice.actions;

export const selectUser = (state) => state.signin.user;
export const selectSupportTickets = (state) => state.signin.supportTickets;
