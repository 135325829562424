import { TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editJdPoint } from "../AddRole/AddRole.reducer";

export default function JDInput({ item }) {
    const dispatch = useDispatch();
    const [point, setPoint] = useState(item.text);

    const handleSavePoint = () => {
        dispatch(
            editJdPoint({
                id: item.id,
                name: point,
            })
        );
    };

    return (
        <TextField
            size="small"
            fullWidth
            value={point}
            label={item.key}
            onChange={(e) => setPoint(e.target.value)}
            onBlur={handleSavePoint}
        />
    );
}
