import { get, isEmpty } from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { setSuccessNotification } from "../../components/Notification/Notification.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";

import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import {
    addRoleComment,
    deleteRoleComment,
    editRoleComment,
    getRoleComments,
    resetRoleComments,
    setRoleComments,
} from "./RoleComments.reducer";

function* getRoleCommentsSaga({ payload }) {
    try {
        const { action, jobId } = payload;

        yield put(startAction({ action }));

        const response = yield call(new API().get, `/jobs/comments/${jobId}`);

        if (isEmpty(response)) return;

        yield put(setRoleComments(response));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* addRoleCommentSaga({ payload }) {
    try {
        const { jobId, comment, onSuccess, action } = payload;

        yield put(startAction({ action }));

        const response = yield call(new API().post, `/jobs/comments/${jobId}`, {
            comment,
        });

        if (isEmpty(response)) return;

        onSuccess();

        const state = yield select();
        const commentsStateValue = get(state, "roleComments.comments");
        yield put(setRoleComments([...commentsStateValue, response]));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editRoleCommentSaga({ payload }) {
    try {
        const { commentId, text, onSuccess, action } = payload;

        yield put(startAction({ action }));

        const response = yield call(
            new API().put,
            `/jobs/comments/${commentId}`,
            {
                comment: text,
            }
        );

        if (isEmpty(response)) return;

        onSuccess();

        const state = yield select();
        const commentsStateValue = get(state, "roleComments.comments");
        const updatedComments = commentsStateValue.map((comment) => {
            if (comment._id === response._id) {
                return {
                    ...comment,
                    text: response.text,
                };
            }
            return comment;
        });
        yield put(setRoleComments(updatedComments));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteRoleCommentSaga({ payload }) {
    try {
        const { commentId, onSuccess, action } = payload;

        yield put(startAction({ action }));

        const response = yield call(
            new API().delete,
            `/jobs/comments/${commentId}`
        );

        if (!response?.acknowledged) return;

        onSuccess();

        const state = yield select();
        const commentsStateValue = get(state, "roleComments.comments");
        const updatedComments = commentsStateValue.filter(
            (item) => item._id !== commentId
        );
        yield put(setRoleComments(updatedComments));
    } catch (e) {
        console.error(e);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        yield takeLatest(getRoleComments.type, getRoleCommentsSaga),
        yield takeLatest(addRoleComment.type, addRoleCommentSaga),
        yield takeLatest(editRoleComment.type, editRoleCommentSaga),
        yield takeLatest(deleteRoleComment.type, deleteRoleCommentSaga),
    ];
    yield takeLatest(resetRoleComments.type, CancelSagas, tasks);
}
