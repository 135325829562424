import { all, fork } from "redux-saga/effects";

import signin from "../../pages/loginPage/LoginPage.saga";
import addRole from "../../components/AddRole/AddRole.saga";
import candidates from "../../pages/candidatePage/CandidatePage.saga";
import companies from "../../components/CompanyDropdown/CompanyDropdown.saga";
import superAdmin from "../../pages/userListPage/UserListPage.saga";
import roleComments from "../../pages/roleComments/RoleComments.saga";

export function* watchSagas() {
    yield all([
        fork(signin),
        fork(addRole),
        fork(candidates),
        fork(companies),
        fork(superAdmin),
        fork(roleComments),
    ]);
}
