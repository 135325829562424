import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import dayjs from "dayjs";

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FolderIcon from "@mui/icons-material/Folder";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";

import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import {
    editSupportTicket,
    getSupportTickets,
    selectSupportTickets,
    selectUser,
} from "../loginPage/LoginPage.reducer";
import { selectCompanies } from "../../components/CompanyDropdown/CompanyDropdown.reducer";

function DocumentModal({ documents, jd }) {
    const [open, setOpen] = useState(false);

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    return (
        <>
            <IconButton size="small" onClick={onOpen}>
                <FolderIcon />
            </IconButton>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
                <DialogTitle>
                    Supporting documents
                    <IconButton
                        size="small"
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton href={jd}>
                                <ListItemIcon>
                                    <PictureAsPdfIcon />
                                </ListItemIcon>
                                <ListItemText primary="JD" />
                            </ListItemButton>
                        </ListItem>
                        {documents?.map((document) => (
                            <ListItem disablePadding>
                                <ListItemButton href={document.filePath}>
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={document.fileName} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default function SupportPage() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const companies = useSelector(selectCompanies);
    const supportTickets = useSelector(selectSupportTickets);
    const isDataLoading = useSelector(checkIfLoading(getSupportTickets.type));
    const isEditTicketLoading = useSelector(
        checkIfLoading(editSupportTicket.type)
    );
    const tableHeight = user.role === "ADMIN" ? 86 : 32;

    const [resolveTicket, setResolveTicket] = useState({
        open: false,
        ticket: {},
        status: "",
    });
    const [remarks, setRemarks] = useState("");
    const [sendEmail, setSendEmail] = useState(false);
    const [company, setCompany] = useState("All");

    const closeResolveModal = () => {
        setResolveTicket({
            open: false,
            ticket: {},
        });
        setRemarks("");
    };

    useEffect(() => {
        const supportCompany = user.role === "CLIENT" ? user.company : company;
        dispatch(
            getSupportTickets({
                action: getSupportTickets.type,
                company: supportCompany,
            })
        );
    }, [company]);

    const columns = [
        { field: "description", headerName: "Support Ticket", flex: 1 },
        {
            field: "resource",
            headerName: "Documents",
            width: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return params.row.resource ||
                    !isEmpty(params.row.supportingDocuments) ? (
                    <DocumentModal
                        jd={params.row.resource}
                        documents={params.row.supportingDocuments}
                    />
                ) : (
                    "N/A"
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
        },
        {
            field: "createdAt",
            headerName: "Created On",
            width: 100,
            valueGetter: (params) =>
                dayjs(params.row.createdAt).format("MM/DD/YYYY"),
        },
    ];

    if (user.role === "ADMIN") {
        columns.splice(0, 0, {
            field: "title",
            headerName: "Title",
            valueGetter: (params) => params.row.title ?? "N/A",
        });
        columns.push(
            {
                field: "company",
                headerName: "Company",
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => params.row.company.name,
            },
            {
                field: "actions",
                headerName: "Mark as",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Stack direction="row" spacing={1}>
                            {params.row.status !== "CLOSED" ? (
                                <Button
                                    onClick={() =>
                                        setResolveTicket({
                                            open: true,
                                            ticket: params.row,
                                            status: "CLOSED",
                                        })
                                    }
                                >
                                    Resolved
                                </Button>
                            ) : null}
                            {!(
                                params.row.status === "IN_PROGRESS" ||
                                params.row.status === "CLOSED"
                            ) ? (
                                <Button
                                    onClick={() =>
                                        setResolveTicket({
                                            open: true,
                                            ticket: params.row,
                                            status: "IN_PROGRESS",
                                        })
                                    }
                                >
                                    In Progress
                                </Button>
                            ) : null}
                        </Stack>
                    );
                },
            }
        );
    }

    const handleResolveTicket = () => {
        dispatch(
            editSupportTicket({
                action: editSupportTicket.type,
                id: resolveTicket.ticket._id,
                reply: remarks,
                status: resolveTicket.status,
                sendEmail,
            })
        );
        closeResolveModal();
    };

    return (
        <>
            <Stack padding={2} flex={1}>
                {user.role === "ADMIN" ? (
                    <Stack direction="row" justifyContent="flex-end" mb={2}>
                        <TextField
                            label="Select company"
                            select
                            size="small"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            sx={{ minWidth: 120 }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            {companies.map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                ) : null}
                <Box
                    sx={{
                        height: `calc(100vh - ${tableHeight}px)`,
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={supportTickets}
                        columns={columns}
                        loading={isDataLoading}
                        getRowId={(row) => row._id}
                        hideFooter
                        disableRowSelectionOnClick
                    />
                </Box>
            </Stack>
            <Dialog
                open={resolveTicket.open}
                onClose={closeResolveModal}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Resolve Ticket</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={2} fontStyle="italic">
                        <span
                            dangerouslySetInnerHTML={{
                                __html:
                                    resolveTicket.status === "IN_PROGRESS"
                                        ? `Dear {user},<br><br>HireQuotient has reviewed your support request raised on {time, date}. We are currently working on resolving this. Please reach out to your Customer Service Manager for additional queries.`
                                        : `Dear {user},<br><br>HireQuotient has resolved your support ticket request raised on {time, date}. We hope this resolution was satisfactory. Please reach out to your Customer Service Manager if you have additional queries on this.<br><br>See HireQuotient remarks below “...” `,
                            }}
                        />
                    </DialogContentText>
                    <TextField
                        fullWidth
                        hiddenLabel
                        multiline
                        variant="filled"
                        rows={4}
                        placeholder={"Write remarks regarding this ticket"}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                    <Stack direction="row" alignItems="center">
                        <Checkbox
                            size="small"
                            checked={sendEmail}
                            onChange={(e) => setSendEmail(e.target.checked)}
                        />
                        <Typography variant="subtitle2">
                            Send an email to client regarding this status change
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={isEditTicketLoading}
                        onClick={handleResolveTicket}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
