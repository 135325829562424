import { createSlice } from "@reduxjs/toolkit";
import { generateUUID } from "../../utils/helper";

export const stageInitialState = {
    info: "",
    summary: "",
    date: "",
    points: [],
};

const initialState = {
    stage1: stageInitialState,
    stage2: stageInitialState,
    stage3: stageInitialState,
};

const reducers = {
    addPoint(state, { payload }) {
        const { key } = payload;
        state[key].points.push({ id: generateUUID(), date: "", text: "" });
    },
    editPoint(state, { payload }) {
        const { key, id, type, text } = payload;
        const tmpStatePoints = state[key].points.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    [type]: text,
                };
            }
            return item;
        });
        state[key].points = tmpStatePoints;
    },
    deletePoint(state, { payload }) {
        const { key, id } = payload;
        state[key].points = state[key].points.filter((item) => item.id !== id);
    },
    setStage(state, { payload }) {
        const { key, value } = payload;
        state[key] = value;
    },
    editStage(state, { payload }) {
        const { key: stageId, type, text } = payload;
        state[stageId][type] = text;
    },
};

export const editRoleSlice = createSlice({
    name: "editRole",
    initialState,
    reducers,
});

export default editRoleSlice.reducer;

export const { addPoint, editPoint, deletePoint, setStage, editStage } =
    editRoleSlice.actions;

export const selectStage1 = (state) => state.editRole.stage1;
export const selectStage2 = (state) => state.editRole.stage2;
export const selectStage3 = (state) => state.editRole.stage3;
