import { Typography } from "@mui/material";
import React, { useState } from "react";

export default function LineClampWithReadMore({ text, wordsToShow }) {
    const [expanded, setExpanded] = useState(false);
    const shouldShowReadMoreButton = text.split(" ").length > wordsToShow;

    const toggleReadMore = () => {
        setExpanded((prevState) => !prevState);
    };

    const getTextToShow = () => {
        if (expanded) {
            return text;
        }
        const lines = text.split(" ").slice(0, wordsToShow);
        return lines.join(" ");
    };

    return (
        <Typography variant="subtitle1" fontSize={14}>
            {getTextToShow()}
            {shouldShowReadMoreButton && (
                <Typography
                    ml={0.5}
                    color="#000"
                    fontWeight={500}
                    display="inline"
                    sx={{
                        cursor: "pointer",
                        opacity: 1,
                    }}
                    onClick={toggleReadMore}
                >
                    {expanded ? "Read Less" : "Read More"}
                </Typography>
            )}
        </Typography>
    );
}
