import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import {
    Stack,
    Typography,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";

import LineClampWithReadMore from "../../components/common/SeeMore";
import CandidateTable from "../../components/CandidateTable/CandidateTable";
import EditRole from "../../components/EditRole/EditRole";
import { selectCompanyRoles } from "../../components/AddRole/AddRole.reducer";

function Documents({ documents }) {
    const [openModal, setOpenModal] = useState(false);

    const showModal = () => setOpenModal(true);
    const closeModal = () => setOpenModal(false);

    return (
        <>
            <Tooltip title="Documents">
                <IconButton onClick={showModal}>
                    <FolderIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={openModal}
                onClose={closeModal}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    Documents regarding the role
                    <IconButton
                        size="small"
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                        }}
                        onClick={closeModal}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {documents.map((document, index) => (
                            <ListItem disablePadding>
                                <ListItemButton
                                    href={
                                        index === 0
                                            ? document
                                            : document.filePath
                                    }
                                >
                                    <ListItemIcon>
                                        {index === 0 ? (
                                            <PictureAsPdfIcon />
                                        ) : (
                                            <DescriptionIcon />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            index === 0
                                                ? "JD"
                                                : document.fileName
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
}

function CandidatePage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const roles = useSelector(selectCompanyRoles);
    const currentRole = roles.find((item) => item._id === id);
    const { jd = "", supportingDocuments = [] } = currentRole || {};
    const documents = [jd, ...supportingDocuments];

    return (
        <Stack padding={2} flex={1}>
            <Stack mb={2} spacing={1}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography variant="subtitle1" fontWeight={700}>
                        {currentRole?.role}
                    </Typography>
                    <Documents documents={documents} />
                    {!isEmpty(currentRole) && <EditRole role={currentRole} />}
                    <Tooltip title="Role Progress">
                        <IconButton onClick={() => navigate(`/${id}/progress`)}>
                            <DateRangeIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Comments">
                        <IconButton onClick={() => navigate(`/${id}/comments`)}>
                            <CommentIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
                {currentRole?.description ? (
                    <LineClampWithReadMore
                        text={currentRole.description}
                        wordsToShow={100}
                    />
                ) : null}
            </Stack>
            <CandidateTable filters={currentRole?.filters} />
        </Stack>
    );
}

export default CandidatePage;
