import axios from "axios";
import { get } from "lodash";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { setErrorNotification } from "../components/Notification/Notification.reducer";
import store from "../store";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const apiBase = `${baseUrl}`;
export const openExtensionLink =
    "https://www.linkedin.com/search/results/people/";
export const openLinkedinRecruiter = "https://www.linkedin.com/talent/home";

const responseBody = (response) => {
    return response.data;
};

const responseHeader = (response) => {
    return response;
};

class API {
    instance;
    noAuth;
    getHeader;
    redirectOnError;

    constructor(
        config = {
            baseURL: apiBase,
            timeout: 120000,
        },
        noAuth,
        getHeader,
        redirectOnError
    ) {
        this.instance = axios.create(config);
        this.noAuth = noAuth || false;
        this.getHeader = getHeader || false;
        this.redirectOnError = redirectOnError || false;
        this.instance.interceptors.request.use(this.requestInterceptors);
        this.instance.interceptors.response.use(
            this.responseSuccessInterceptors,
            this.responseErrorInterceptors
        );
    }

    responseSuccessInterceptors = (response) => response;

    responseErrorInterceptors = (response) => {
        const error = get(response, "response.data") || {};
        let isTokenExpired =
            error?.message === "Token expired" ||
            error.error === "Token expired";
        if (isTokenExpired) {
            console.log("*****Token Expired");
        } else {
            store.dispatch(setErrorNotification(error?.error));
        }
        return response;
    };

    requestInterceptors = async (params) => {
        const auth = firebase.auth();
        const token = await auth.currentUser?.getIdToken(true);
        const newParams = {
            ...(params || {}),
            headers: {
                ...(get(params, "headers") || {}),
                appType: "web",
                version: 1.0,
                timezone: -330,
                "x-authorization": token,
            },
            defaults: {
                mode: "cors",
                withCredentials: false,
                credentials: "same-origin",
                headers: {
                    ...(get(params, "headers") || {}),
                    appType: "web",
                    version: 1.0,
                    timezone: -330,
                    "x-authorization": token,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers":
                        "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Methods":
                        "GET, PUT, POST, DELETE, OPTIONS",
                },
            },
        };
        return newParams;
    };

    setLocalStorage = ({ key, value }) => {
        localStorage.setItem(key, value);
    };

    getLocalStorage = (key) => {
        return localStorage.getItem(key);
    };

    get = (url, config) =>
        this.instance
            .get(url, config)
            .then(this.getHeader ? responseHeader : responseBody);
    post = (url, body, config) =>
        this.instance
            .post(url, body, config)
            .then(this.getHeader ? responseHeader : responseBody);
    put = (url, body, config) =>
        this.instance
            .put(url, body, config)
            .then(this.getHeader ? responseHeader : responseBody);
    delete = (url, config) =>
        this.instance
            .delete(url, config)
            .then(this.getHeader ? responseHeader : responseBody);
}

export default API;
