import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    Typography,
    Tooltip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { candidateStatus } from "../../constants/constant";
import { changeCandidateStatus } from "../../pages/candidatePage/CandidatePage.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import ButtonTextWithLoading from "../CustomMUIComponents/ButtonTextWithLoading";

export default function ChangeStatus({ candidate, isRejected }) {
    const dispatch = useDispatch();
    const isStatusUpdating = useSelector(
        checkIfLoading(changeCandidateStatus.type)
    );

    const [open, setOpen] = useState(false);
    const [view, setView] = useState("");
    const [remarks, setRemarks] = useState("");
    const [error, setError] = useState(false);

    const handleOpen = (view) => {
        setView(view);
        setOpen(true);
    };

    const handleClose = () => {
        setView("");
        setRemarks("");
        setOpen(false);
    };

    const handleChangeStatus = () => {
        if (view === "reject" && !remarks) {
            setError(true);
            return;
        }
        dispatch(
            changeCandidateStatus({
                action: changeCandidateStatus.type,
                id: candidate._id,
                status: candidateStatus[view],
                clientRemarks: remarks,
            })
        );
        setError(false);
        handleClose();
    };

    return (
        <>
            <Tooltip title="Approve candidate">
                <IconButton
                    variant="outlined"
                    color={"success"}
                    onClick={() => {
                        handleOpen("approve");
                    }}
                >
                    <CheckIcon />
                </IconButton>
            </Tooltip>
            {!isRejected && (
                <Tooltip title="Reject candidate">
                    <IconButton
                        variant="outlined"
                        color={"error"}
                        onClick={() => {
                            handleOpen("reject");
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="subtitle1">
                        Are you sure you want to {view} this candidate?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        hiddenLabel={true}
                        multiline
                        variant="filled"
                        rows={4}
                        placeholder={"Remarks if any"}
                        value={remarks}
                        error={error}
                        helperText={error ? "*Required" : ""}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        No
                    </Button>
                    <ButtonTextWithLoading
                        variant="contained"
                        onClick={handleChangeStatus}
                        isLoading={isStatusUpdating}
                        text="Yes"
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
