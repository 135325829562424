import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DataGrid } from "@mui/x-data-grid";

import HomePage from "../homePage/HomePage";

import {
    getCompanyRolesWithStats,
    selectCompanyRolesWithStats,
} from "../../components/AddRole/AddRole.reducer";
import {
    getCompanies,
    selectCompany,
} from "../../components/CompanyDropdown/CompanyDropdown.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectUser } from "../loginPage/LoginPage.reducer";

const columns = [
    { field: "role", headerName: "Role", flex: 1 },
    { field: "status", headerName: "Role Status", flex: 1 },
    {
        field: "jd",
        headerName: "JD",
        headerAlign: "center",
        align: "center",
        disableRowSelectionOnClick: true,
        renderCell: (params) => {
            return params.row.jd ? (
                <IconButton
                    href={params.row.jd}
                    onClick={(e) => {
                        e.ignore = true;
                    }}
                >
                    <PictureAsPdfIcon />
                </IconButton>
            ) : (
                "N/A"
            );
        },
    },
    {
        field: "createdAt",
        headerName: "Created On",
        flex: 1,
        valueGetter: (params) =>
            dayjs(params.row.createdAt).format("MM/DD/YYYY"),
    },
    {
        field: "accepted",
        headerName: "Resumes Accepted",
        flex: 1,
    },
    {
        field: "inReview",
        headerName: "To Be Reviewed",
        flex: 1,
    },
];

function getColumns(userRole) {
    const tmpCols = [...columns];
    if (userRole === "ADMIN") {
        tmpCols.splice(
            4,
            0,
            {
                field: "received",
                headerName: "Resumes added",
                flex: 1,
            },
            {
                field: "approved",
                headerName: "Resumes sent",
                flex: 1,
            }
        );
    } else {
        tmpCols.splice(4, 0, {
            field: "approved",
            headerName: "Resumes received",
            flex: 1,
        });
    }
    return tmpCols;
}

export default function AllRolesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const allRoles = useSelector(selectCompanyRolesWithStats);
    const currCompany = useSelector(selectCompany);
    const isCompaniesLoading = useSelector(checkIfLoading(getCompanies.type));
    const isRolesWithStatsLoading = useSelector(
        checkIfLoading(getCompanyRolesWithStats.type)
    );
    const updatedColumns = getColumns(user.role);

    useEffect(() => {
        if (isEmpty(user) || (user.role === "ADMIN" && isEmpty(currCompany)))
            return;

        const company = user.role === "CLIENT" ? user.company : currCompany._id;
        dispatch(
            getCompanyRolesWithStats({
                action: getCompanyRolesWithStats.type,
                company,
            })
        );
    }, [user, isCompaniesLoading, currCompany]);

    return (
        <>
            {isRolesWithStatsLoading ? (
                <Stack
                    width={"100%"}
                    flex={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <CircularProgress />
                </Stack>
            ) : allRoles?.jobs?.length ? (
                <Stack padding={2} flex={1} maxHeight={"95vh"}>
                    <Stack
                        direction={"row"}
                        spacing={2}
                        bgcolor={"#fff"}
                        p={2}
                        mb={3}
                        justifyContent={"space-between"}
                    >
                        <Typography variant="subtitle1">
                            <strong>{allRoles.totalJobs}</strong>&ensp;Total
                            roles
                        </Typography>
                        {user.role === "ADMIN" ? (
                            <Typography variant="subtitle1">
                                <strong>{allRoles.totalCandidates}</strong>
                                &ensp;Resumes added
                            </Typography>
                        ) : null}
                        <Typography variant="subtitle1">
                            <strong>{allRoles.totalApproved}</strong>
                            &ensp;Resumes{" "}
                            {user.role === "ADMIN" ? "sent" : "received"}
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>{allRoles.totalAccepted}</strong>&ensp;Total
                            resumes accepted
                        </Typography>
                        <Typography variant="subtitle1">
                            <strong>{allRoles.totalInReview}</strong>&ensp;Total
                            to be reviewed
                        </Typography>
                    </Stack>
                    <DataGrid
                        rows={allRoles.jobs}
                        getRowId={(row) => row._id}
                        columns={updatedColumns}
                        hideFooter
                        disableRowSelectionOnClick
                        onRowClick={(row) => navigate(`/${row.id}`)}
                    />
                </Stack>
            ) : (
                <HomePage />
            )}
        </>
    );
}
