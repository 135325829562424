import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import createSagaMiddleware from "redux-saga";

import { watchSagas } from "./sagas";
const saga = createSagaMiddleware();

//redux dev tool
const composeEnhancers =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(saga)));
saga.run(watchSagas);

export default store;

function UnloadHandler() {
    window.removeEventListener("unload", UnloadHandler, false);
}

window.addEventListener("unload", UnloadHandler, false);

window.addEventListener(
    "pageshow",
    function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    },
    false
);
