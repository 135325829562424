import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ChangeStatus from "../ChangeStatus/ChangeStatus";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";

import {
    hideEmailContent,
    hideNameContent,
    hideNumberContent,
} from "../../utils/helper";
import {
    editCandidate,
    getCandidates,
    selectCandidates,
} from "../../pages/candidatePage/CandidatePage.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectUser } from "../../pages/loginPage/LoginPage.reducer";
import style from "./CandidateTable.module.css";
import AddCandidate from "./AddCandidate";
import BulkUpload from "./BulkUpload";
import UploadCandidateCv from "./UploadCandidateCv";
import RoleOverview from "./RoleOverview";
import dayjs from "dayjs";
import AddComments from "./AddComments";
import { isEmpty } from "lodash";
import BulkUploadCVs from "./BulkUploadCVs";

function CandidateTableBody({
    row,
    userRole,
    onEditCandidate,
    onApproveCandidate,
}) {
    const isAccepted = row.status === "ACCEPTED";
    const isRejected = row.status === "REJECTED";
    const date = new Date(row.createdAt);
    let backgroundColor = "initial";
    if (isAccepted) backgroundColor = "#E5F9DB";
    if (isRejected) backgroundColor = "#eeeeee";
    const seeMoreLength = `${row.clientRemarks || ""}${
        row.comments?.map((item) => `${item.postedOn}${item.text}`) || ""
    }`;
    const isApprovingCandidate = useSelector(
        checkIfLoading(`${row._id}${editCandidate.type}`)
    );

    const [documentModal, setDocumentModal] = useState({
        open: false,
        candidate: {},
    });

    const showDocumentModal = (candidate) =>
        setDocumentModal({
            open: true,
            candidate,
        });
    const closeDocumentModal = () => {
        setDocumentModal({ open: false, candidate: {} });
    };

    if (userRole === "CLIENT" && row.hqCandidateStatus !== "APPROVED") return;
    return (
        <>
            <TableRow
                sx={{
                    backgroundColor,
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                    display:
                        userRole === "CLIENT"
                            ? row.cv
                                ? "table-row"
                                : "none"
                            : "table-row",
                }}
            >
                <TableCell width="20%">
                    <Stack
                        sx={{
                            "& >p": {
                                margin: 0,
                                marginBottom: "0.5rem",
                            },
                        }}
                    >
                        {row.name ? (
                            !isAccepted && userRole === "CLIENT" ? (
                                <Tooltip
                                    title={
                                        "Details would become visible if you decide to select this candidate"
                                    }
                                >
                                    <p>{hideNameContent(row.name)}</p>
                                </Tooltip>
                            ) : (
                                <p>{row.name}</p>
                            )
                        ) : (
                            "-"
                        )}
                        {row.email ? (
                            !isAccepted && userRole === "CLIENT" ? (
                                <p>{hideEmailContent(row.email)}</p>
                            ) : (
                                <p>{row.email}</p>
                            )
                        ) : (
                            "-"
                        )}
                        {row.phoneNumber ? (
                            !isAccepted && userRole === "CLIENT" ? (
                                <p>
                                    {hideNumberContent(row.phoneNumber, 2, 5)}
                                </p>
                            ) : (
                                <p>{row.phoneNumber}</p>
                            )
                        ) : (
                            "-"
                        )}
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        {row?.linkedIn ? (
                            <Tooltip title="Linkedin">
                                <IconButton
                                    size="small"
                                    href={row.linkedIn}
                                    target="_blank"
                                    disabled={
                                        !isAccepted && userRole === "CLIENT"
                                    }
                                >
                                    <LinkedInIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        <Stack direction="row" alignItems="center">
                            <Tooltip
                                title={row.cv ? "Resume" : "Upload Resume"}
                            >
                                {row.cv ? (
                                    <IconButton size="small" href={row.cv}>
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        size="small"
                                        onClick={() => onEditCandidate(row)}
                                    >
                                        <AddIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Tooltip>
                            <Tooltip title="Documents">
                                <IconButton
                                    size="small"
                                    onClick={() => showDocumentModal(row)}
                                >
                                    <FolderIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell width="10%">
                    <p style={{ margin: 0 }}>{row.status}</p>
                    {row.status === "ACCEPTED" ? (
                        row.acceptedOn ? (
                            <p
                                style={{
                                    marginBottom: 0,
                                    fontSize: 10,
                                }}
                            >
                                (
                                {dayjs(row.acceptedOn)
                                    .locale("en")
                                    .format("M/D/YYYY, hh:mm A")}
                                )
                            </p>
                        ) : null
                    ) : null}
                    {row.status === "REJECTED" ? (
                        row.rejectedOn ? (
                            <p
                                style={{
                                    marginBottom: 0,
                                    fontSize: 10,
                                }}
                            >
                                (
                                {dayjs(row.rejectedOn)
                                    .locale("en")
                                    .format("M/D/YYYY, hh:mm A")}
                                )
                            </p>
                        ) : null
                    ) : null}
                </TableCell>
                <TableCell>
                    <Stack direction="row" justifyContent="space-between">
                        <Tooltip
                            title={
                                seeMoreLength.length > 120 ? (
                                    <>
                                        <p
                                            style={{
                                                margin: 0,
                                            }}
                                        >
                                            {row.clientRemarks
                                                ? row.clientRemarks
                                                : "N/A"}
                                        </p>
                                        <Stack
                                            spacing={1.5}
                                            mt={isEmpty(row.comments) ? 0 : 2}
                                        >
                                            {!isEmpty(row.comments)
                                                ? row.comments.map((item) => {
                                                      return (
                                                          <Stack>
                                                              <p
                                                                  style={{
                                                                      fontSize: 10,
                                                                      fontStyle:
                                                                          "italic",
                                                                      margin: 0,
                                                                  }}
                                                              >
                                                                  Added on{" "}
                                                                  {dayjs(
                                                                      item.postedOn
                                                                  ).format(
                                                                      "MM/DD/YYYY"
                                                                  )}
                                                              </p>
                                                              <p
                                                                  style={{
                                                                      margin: 0,
                                                                  }}
                                                              >
                                                                  {item.text}
                                                              </p>
                                                          </Stack>
                                                      );
                                                  })
                                                : null}
                                        </Stack>
                                    </>
                                ) : null
                            }
                        >
                            <Stack justifyContent={"center"}>
                                {row.clientRemarks
                                    ? row.clientRemarks.slice(0, 120)
                                    : "N/A"}
                                {seeMoreLength.length > 120 ? " ..." : null}
                            </Stack>
                        </Tooltip>
                        {row.status === "ACCEPTED" && userRole === "CLIENT" ? (
                            <AddComments id={row._id} name={row.name} />
                        ) : null}
                    </Stack>
                </TableCell>
                <TableCell>
                    <Tooltip title={row.hqRemarks ? row.hqRemarks : null}>
                        <Stack justifyContent={"center"}>
                            {row.hqRemarks || "N/A"}
                        </Stack>
                    </Tooltip>
                </TableCell>
                {userRole === "ADMIN" && (
                    <TableCell>
                        {row.hqCandidateStatus === "PENDING_APPROVAL" ? (
                            isApprovingCandidate ? (
                                <CircularProgress size={18} />
                            ) : (
                                <Tooltip title="Send candidate to client">
                                    <IconButton
                                        onClick={() => onApproveCandidate(row)}
                                    >
                                        <DoneOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )
                        ) : (
                            "Sent to client"
                        )}
                    </TableCell>
                )}
                <TableCell width={userRole === "ADMIN" ? "15%" : "10%"}>
                    <Stack
                        direction={"row"}
                        spacing={1}
                        className={style.actionContainer}
                    >
                        {userRole === "ADMIN" ? (
                            <Tooltip title="Edit candidate">
                                <IconButton
                                    variant="outlined"
                                    color={"primary"}
                                    sx={{
                                        minWidth: "fit-content",
                                    }}
                                    onClick={() => onEditCandidate(row)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        {!isAccepted && (
                            <ChangeStatus
                                candidate={row}
                                isRejected={isRejected}
                            />
                        )}
                    </Stack>
                </TableCell>
                <TableCell width="11%">
                    {dayjs(date).locale("en").format("M/D/YYYY")}
                </TableCell>
            </TableRow>
            <Dialog
                open={documentModal.open}
                onClose={closeDocumentModal}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    Supporting documents for {documentModal.candidate.name}
                    <IconButton
                        size="small"
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                        }}
                        onClick={closeDocumentModal}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {!isEmpty(documentModal.candidate?.supportingDocuments) ? (
                        <List>
                            {documentModal.candidate?.supportingDocuments.map(
                                (document) => (
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            href={document.filePath}
                                        >
                                            <ListItemIcon>
                                                <DescriptionIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={document.fileName}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            )}
                        </List>
                    ) : (
                        <Typography>No documents found.</Typography>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default function CandidateTable({ filters }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const candidates = useSelector(selectCandidates);
    const isCandidatesLoading = useSelector(checkIfLoading(getCandidates.type));

    const [uploadCandidateCv, setUploadCandidateCv] = useState(false);
    const [showUploadCandidate, setShowUploadCandidate] = useState(false);
    const [showBulkUpload, setShowsBulkUpload] = useState(false);
    const [updateCandidate, setUpdateCandidate] = useState("");
    const [showBulkUploadCv, setShowBulkUploadCv] = useState(false);

    useEffect(() => {
        dispatch(getCandidates({ action: getCandidates.type, id }));
    }, [id]);

    if (isCandidatesLoading) {
        return (
            <Box
                width={"100%"}
                height={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flex={1}
            >
                <CircularProgress />
            </Box>
        );
    }

    const onEditCandidate = (candidate) => {
        setUpdateCandidate(candidate);
        setShowUploadCandidate(true);
    };

    const onCloseAddCandidate = () => {
        setUpdateCandidate("");
        setShowUploadCandidate(false);
    };

    const onCloseUploadCandidateCv = () => {
        setUploadCandidateCv(false);
    };

    const onApproveCandidate = (candidate) => {
        dispatch(
            editCandidate({
                ...candidate,
                linkedin: candidate.linkedIn,
                candidateId: candidate._id,
                hqCandidateStatus: "APPROVED",
                action: `${candidate._id}${editCandidate.type}`,
            })
        );
    };

    const isAdmin = user.role === "ADMIN";

    return (
        <>
            <div className={style.statsContainer}>
                <RoleOverview filters={filters} userRole={user.role} />
                {isAdmin && (
                    <div className={style.statsText}>
                        <Tooltip title="Add candidates">
                            <IconButton
                                variant="contained"
                                onClick={() => setUploadCandidateCv(true)}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Bulk upload candidates">
                            <IconButton
                                variant="contained"
                                onClick={() => setShowsBulkUpload(true)}
                            >
                                <GroupAddIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Bulk upload CVs">
                            <IconButton
                                variant="contained"
                                onClick={() => setShowBulkUploadCv(true)}
                            >
                                <CloudUploadIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </div>
            <TableContainer
                component={Paper}
                sx={{ minWidth: 650, maxHeight: "calc(100vh - 180px)" }}
            >
                <Table stickyHeader aria-label="role-candidates-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Candidate Profile</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>
                                {user.role === "ADMIN"
                                    ? "Client Remarks"
                                    : "Your Remarks"}
                            </TableCell>
                            <TableCell>
                                {user.role === "ADMIN"
                                    ? "Your Remarks"
                                    : "HQ Remarks"}
                            </TableCell>
                            {user.role === "ADMIN" && (
                                <TableCell>HQ Status</TableCell>
                            )}
                            <TableCell>Actions</TableCell>
                            <TableCell>Uploaded on</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {candidates.map((row) => (
                            <CandidateTableBody
                                key={row._id}
                                userRole={user.role}
                                row={row}
                                onEditCandidate={onEditCandidate}
                                onApproveCandidate={onApproveCandidate}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {uploadCandidateCv && (
                <UploadCandidateCv
                    role={id}
                    open={uploadCandidateCv}
                    handleClose={onCloseUploadCandidateCv}
                    onSubmit={onEditCandidate}
                />
            )}
            {showUploadCandidate && (
                <AddCandidate
                    open={showUploadCandidate}
                    handleClose={onCloseAddCandidate}
                    {...updateCandidate}
                />
            )}
            {showBulkUpload && (
                <BulkUpload
                    open={showBulkUpload}
                    handleClose={() => setShowsBulkUpload(false)}
                />
            )}
            {showBulkUploadCv && (
                <BulkUploadCVs
                    open={showBulkUploadCv}
                    handleClose={() => setShowBulkUploadCv(false)}
                    role={id}
                />
            )}
        </>
    );
}
