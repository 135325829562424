import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    Navigate,
    useLocation,
} from "react-router-dom";
import { get } from "lodash";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LoginPage from "./pages/loginPage/LoginPage";
import { useDispatch } from "react-redux";
import { authLogin } from "./pages/loginPage/LoginPage.reducer";
import { Box, CircularProgress } from "@mui/material";
import CandidatePage from "./pages/candidatePage/candidatePage";
import Notification from "./components/Notification/Notification";
import AllRolesPage from "./pages/allRoles/AllRolesPage";
import SupportPage from "./pages/supportPage/SupportPage";
import UserListPage from "./pages/userListPage/UserListPage.jsx";
import CompanyListPage from "./pages/companyListPage/CompanyListPage.jsx";
import AppContainer from "./components/AppContainer/AppContainer";
import HomePage from "./pages/homePage/HomePage";
import CreatePassword from "./pages/createPassword/CreatePassword";
import RoleProgress from "./pages/roleProgress/RoleProgress";
import RoleComments from "./pages/roleComments/RoleComments.jsx";

function PrivateRoute() {
    const auth = firebase.auth();
    const token = auth.currentUser?.getIdToken(true);
    const location = useLocation();

    return token ? (
        <Outlet />
    ) : (
        <Navigate to="/login" state={{ from: location }} />
    );
}

function App() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = firebase.auth();
        auth.onAuthStateChanged(async (user) => {
            setLoading(false);
            if (user) {
                const currentUser = get(user, "multiFactor.user") || {};
                dispatch(
                    authLogin({
                        action: authLogin.type,
                        user: currentUser,
                        displayName: currentUser.displayName,
                        email: currentUser.email,
                    })
                );
            }
        });
    }, []);

    if (loading) {
        return (
            <Box
                width={"100%"}
                minHeight={"100vh"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <CircularProgress />
            </Box>
        );
    }
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route element={<PrivateRoute />}>
                        <Route
                            path="/"
                            element={
                                <AppContainer>
                                    <HomePage />
                                </AppContainer>
                            }
                        />
                        <Route
                            path="/create-password"
                            element={<CreatePassword />}
                        />
                        <Route
                            path="/overview"
                            element={
                                <AppContainer>
                                    <AllRolesPage />
                                </AppContainer>
                            }
                        />
                        <Route
                            path="/:id"
                            element={
                                <AppContainer>
                                    <CandidatePage />
                                </AppContainer>
                            }
                        />
                        <Route
                            path="/:id/progress"
                            element={
                                <AppContainer>
                                    <RoleProgress />
                                </AppContainer>
                            }
                        />
                        <Route
                            path="/:id/comments"
                            element={
                                <AppContainer>
                                    <RoleComments />
                                </AppContainer>
                            }
                        />
                        <Route
                            path="/support"
                            element={
                                <AppContainer>
                                    <SupportPage />
                                </AppContainer>
                            }
                        />
                        <Route
                            path="/users-list"
                            element={
                                <AppContainer>
                                    <UserListPage />
                                </AppContainer>
                            }
                        />
                        <Route
                            path="/companies-list"
                            element={
                                <AppContainer>
                                    <CompanyListPage />
                                </AppContainer>
                            }
                        />
                    </Route>
                </Routes>
            </Router>
            <Notification />
        </>
    );
}

export default App;
