import { uniqueId } from "lodash";

export const hideNameContent = (name) => {
    if (!name) return;

    const maxLength = 5;
    const length = Math.floor(Math.random() * Math.min(maxLength, name.length));
    const startIndex = Math.floor(Math.random() * (name.length - length + 1));
    const sequence = name.substring(startIndex, startIndex + length);
    const hiddenName = name.replace(sequence, "*".repeat(sequence.length));
    return hiddenName;
};

export const hideEmailContent = (email) => {
    if (!email) return;

    const parts = email.split("@");
    const maskedEmail = "*".repeat(parts[0].length) + "@" + parts[1];
    return maskedEmail;
};

export const hideNumberContent = (phoneNumber, startIndex, sequenceLength) => {
    // Check if the phoneNumber is valid
    if (!phoneNumber || typeof phoneNumber !== "string") {
        return phoneNumber;
    }

    // Check if startIndex and sequenceLength are valid
    if (
        typeof startIndex !== "number" ||
        typeof sequenceLength !== "number" ||
        startIndex < 0 ||
        sequenceLength <= 0 ||
        startIndex + sequenceLength > phoneNumber.length
    ) {
        return phoneNumber;
    }

    // Hide the specified sequence
    let hiddenDigits = "";
    for (let i = 0; i < sequenceLength; i++) {
        hiddenDigits += "*";
    }

    phoneNumber =
        phoneNumber.substring(0, startIndex) +
        hiddenDigits +
        phoneNumber.substring(startIndex + sequenceLength);

    return phoneNumber;
};

export const setLocalStorage = ({ key, value }) => {
    localStorage.setItem(key, value);
};

export const supportBaseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    minWidth: 400,
    outline: "none",
    fontSize: 14,
    marginTop: 16,
    transition: "border .24s ease-in-out",
};

export const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    width: 400,
    outline: "none",
    transition: "border .24s ease-in-out",
};

export const focusedStyle = {
    borderColor: "#2196f3",
};

export const acceptStyle = {
    borderColor: "#00e676",
};

export const rejectStyle = {
    borderColor: "#ff1744",
};

export function generateUUID() {
    let count = parseInt(uniqueId()),
        n = count * 16,
        b = Math.floor(Math.pow(1.57, Math.random() * 100)),
        c = b + n,
        hex = c.toString(16),
        prefix = "uh";
    return prefix + "_" + hex;
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function saveBinaryToFile(response) {
    // Create a blob from the binary data
    const blob = new Blob([response]);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "cleaned_cv.zip";

    // Append the link element to the document body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up by removing the link element
    document.body.removeChild(link);
}

// Helper function to dynamically sort rows given fieldName and order
export function dynamicSort(property, sortOrder) {
    return (a, b) => {
        const fieldA =
            typeof a[property] === "string"
                ? a[property].toLowerCase()
                : a[property];
        const fieldB =
            typeof b[property] === "string"
                ? b[property].toLowerCase()
                : b[property];
        return (fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0) * sortOrder;
    };
}
