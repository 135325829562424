import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { postSupport } from "../../pages/loginPage/LoginPage.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import ButtonTextWithLoading from "../CustomMUIComponents/ButtonTextWithLoading";

export default function WriteToUs() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");
    const isQuerySubmitting = useSelector(checkIfLoading(postSupport.type));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setQuery("");
    };

    const handleSubmitQuery = () => {
        dispatch(postSupport({ action: postSupport.type, query }));
        handleClose();
    };

    return (
        <>
            <Button fullWidth variant="outlined" onClick={handleClickOpen}>
                Write to us
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Contact Us</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please write to us to raise any queries on dashboard,
                        roles, candidates uploaded, search process, or anything
                        else. Our response time is typically less than 4 hours.
                    </DialogContentText>
                    <TextField
                        fullWidth
                        sx={{ marginTop: 2 }}
                        hiddenLabel
                        multiline
                        variant="filled"
                        rows={4}
                        placeholder="Tell us more about your issue"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <ButtonTextWithLoading
                        variant="contained"
                        onClick={handleSubmitQuery}
                        isLoading={isQuerySubmitting}
                        text="Send"
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
