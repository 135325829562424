import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";

import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import {
    addUser,
    deleteUser,
    editUser,
    getUserList,
    selectUserList,
} from "./UserListPage.reducer";
import { selectCompanies } from "../../components/CompanyDropdown/CompanyDropdown.reducer";
import { isEmpty } from "lodash";
import { validateEmail } from "../../utils/helper";
import { setErrorNotification } from "../../components/Notification/Notification.reducer";

const AddUser = ({ edit, open, handleClose }) => {
    const dispatch = useDispatch();
    const companies = useSelector(selectCompanies);
    const [openModal, setOpenModal] = useState(false);
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        role: "",
        company: "",
        roleType: "",
    });

    useEffect(() => {
        if (isEmpty(edit)) return;

        setUser({
            name: edit.name,
            email: edit.email,
            password: edit.password,
            role: edit.role,
            company: edit.company._id,
            roleType: edit.roleType,
        });
        setOpenModal(open);
    }, [edit, open]);

    const showModal = () => setOpenModal(true);
    const closeModal = () => {
        if (handleClose) handleClose();
        setOpenModal(false);
        setUser({
            name: "",
            email: "",
            password: "",
            role: "",
            company: "",
            roleType: "",
        });
    };

    const handleSubmit = () => {
        if (!user.email || !validateEmail(user.email)) {
            dispatch(setErrorNotification("Enter valid email"));
            return;
        }
        closeModal();
        if (edit?._id) {
            dispatch(
                editUser({
                    action: editUser.type,
                    id: edit._id,
                    user: {
                        name: user.name,
                        email: user.email,
                        role: user.role,
                        company: user.company,
                        roleType: user.roleType,
                    },
                })
            );
            return;
        }
        dispatch(
            addUser({
                action: addUser.type,
                user,
            })
        );
    };

    const handleDelete = () => {
        dispatch(
            deleteUser({
                action: addUser.type,
                id: edit._id,
            })
        );
        closeModal();
    };

    return (
        <>
            <Stack direction="row" justifyContent="flex-end" mb={2}>
                <Button variant="contained" onClick={showModal}>
                    Add User
                </Button>
            </Stack>
            <Dialog
                open={openModal}
                onClose={closeModal}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    {edit?._id ? "Edit User" : "Add User"}
                </DialogTitle>
                <DialogContent>
                    <form>
                        <Stack spacing={2} mt={1}>
                            <TextField
                                size="small"
                                label="Name"
                                value={user.name}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        name: e.target.value,
                                    })
                                }
                            />
                            <TextField
                                size="small"
                                label="Email"
                                value={user.email}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        email: e.target.value,
                                    })
                                }
                            />
                            {!edit?._id ? (
                                <TextField
                                    size="small"
                                    label="Password"
                                    value={user.password}
                                    onChange={(e) =>
                                        setUser({
                                            ...user,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            ) : null}
                            <TextField
                                size="small"
                                label="Role"
                                select
                                value={user.role}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        role: e.target.value,
                                    })
                                }
                            >
                                <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                <MenuItem value={"CLIENT"}>Client</MenuItem>
                            </TextField>
                            <TextField
                                size="small"
                                label="Role type"
                                select
                                value={user.roleType}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        roleType: e.target.value,
                                    })
                                }
                            >
                                <MenuItem value={"CSM"}>CSM</MenuItem>
                                <MenuItem value={"RECRUITER"}>
                                    Recruiter
                                </MenuItem>
                                <MenuItem value={"OPS"}>Operations</MenuItem>
                                <MenuItem value={"CLIENT"}>Client</MenuItem>
                            </TextField>
                            <TextField
                                size="small"
                                label="Company"
                                select
                                value={user.company}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        company: e.target.value,
                                    })
                                }
                            >
                                {companies.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions>
                    {edit?._id && (
                        <Button variant="contained" onClick={handleDelete}>
                            Delete
                        </Button>
                    )}
                    <Button variant="contained" onClick={handleSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default function UserListPage() {
    const dispatch = useDispatch();
    const userList = useSelector(selectUserList);
    const isUsersLoading = useSelector(checkIfLoading(getUserList.type));
    const [editUserModal, setEditUserModal] = useState({
        open: false,
        user: {},
    });

    useEffect(() => {
        dispatch(
            getUserList({
                action: getUserList.type,
            })
        );
    }, []);

    const handleClose = () => {
        setEditUserModal({
            open: false,
            company: {},
        });
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "role",
            headerName: "Role",
            flex: 1,
        },
        {
            field: "company",
            headerName: "Company",
            flex: 1,
            renderCell: (params) => params.row?.company?.name || "Default",
        },
        {
            field: "createdAt",
            headerName: "Created On",
            flex: 1,
            renderCell: (params) =>
                dayjs(params.row.createdAt).format("MM/DD/YYYY"),
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Edit user">
                            <IconButton
                                onClick={() =>
                                    setEditUserModal({
                                        open: true,
                                        user: params.row,
                                    })
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    return (
        <Stack p={2} flex={1}>
            <AddUser
                open={editUserModal.open}
                edit={editUserModal.user}
                handleClose={handleClose}
            />
            <Box sx={{ height: "calc(100vh - 82px)", width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={userList}
                    columns={columns}
                    loading={isUsersLoading}
                    hideFooter
                    disableRowSelectionOnClick
                />
            </Box>
        </Stack>
    );
}
