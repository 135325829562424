import { isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { setErrorNotification } from "../Notification/Notification.reducer";

import {
    acceptStyle,
    baseStyle,
    focusedStyle,
    rejectStyle,
} from "../../utils/helper";
import { bulkCleanCVs } from "../../pages/candidatePage/CandidatePage.reducer";

function CVInput({ handleCv }) {
    const onDrop = useCallback((acceptedFiles) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleCv(files);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
    } = useDropzone({
        accept: { "application/pdf": [] },
        multiple: true,
        maxFiles: 20,
        onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop CVs here ...</p>
                ) : (
                    <p>Click to upload your CVs or drag and drop</p>
                )}
            </div>
        </>
    );
}

export default function CleanResume() {
    const dispatch = useDispatch();
    const isCvSubmitting = useSelector(checkIfLoading(bulkCleanCVs.type));

    const [cv, setCv] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const showModal = () => setOpenModal(true);
    const closeModal = () => {
        if (isCvSubmitting) return;
        setCv([]);
        setOpenModal(false);
    }

    const handleSubmit = () => {
        if (isEmpty(cv)) {
            dispatch(setErrorNotification("Please upload CVs"));
            return;
        }
        dispatch(
            bulkCleanCVs({
                action: bulkCleanCVs.type,
                cv,
                onSuccess: closeModal,
            })
        );
    };

    return (
        <>
            <Button variant="outlined" fullWidth onClick={showModal}>
                Clean Resume
            </Button>
            <Dialog open={openModal} onClose={closeModal} maxWidth="lg">
                <DialogTitle>Bulk Clean Resume</DialogTitle>
                <DialogContent>
                    <CVInput handleCv={(val) => setCv(val)} />
                    {!isEmpty(cv)
                        ? cv.map((singleCv) => (
                              <Stack
                                  key={singleCv.name}
                                  mt={2}
                                  direction={"row"}
                                  alignItems={"center"}
                              >
                                  <DescriptionIcon />
                                  <Typography>{singleCv.name}</Typography>
                              </Stack>
                          ))
                        : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isCvSubmitting}
                        sx={{
                            padding: isCvSubmitting
                                ? "0.5rem 1rem"
                                : "6px 16px",
                        }}
                    >
                        {isCvSubmitting ? (
                            <CircularProgress
                                size={20}
                                sx={{
                                    color: "#fff",
                                }}
                            />
                        ) : (
                            "Submit"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
