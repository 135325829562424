import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { List, ListItem, Typography } from "@mui/material";
import CustomPopover from "../CustomPopover/CustomPopover";

import {
    selectCandidates,
    selectTotalAccepted,
    selectTotalApproved,
    selectTotalReview,
} from "../../pages/candidatePage/CandidatePage.reducer";
import style from "./CandidateTable.module.css";

export default function RoleOverview({ filters, userRole }) {
    const candidates = useSelector(selectCandidates);
    const totalAccepted = useSelector(selectTotalAccepted);
    const totalReview = useSelector(selectTotalReview);
    const totalApproved = useSelector(selectTotalApproved);

    const mustHaveFilters = isEmpty(filters)
        ? []
        : filters.filter((item) => item.tag === "MUST");
    const goodToHaveFilters = isEmpty(filters)
        ? []
        : filters.filter((item) => item.tag === "GOOD");

    return (
        <>
            {userRole === "ADMIN" ? (
                <Typography className={style.statsText} variant="subtitle1">
                    <strong>{candidates.length}</strong>&ensp;Resumes added
                </Typography>
            ) : null}
            <Typography className={style.statsText} variant="subtitle1">
                <strong>{totalApproved}</strong>&ensp;Resumes{" "}
                {userRole === "ADMIN" ? "sent" : "received"}
            </Typography>
            <Typography className={style.statsText} variant="subtitle1">
                <strong>{totalAccepted}</strong>&ensp;Resumes accepted
            </Typography>
            <Typography className={style.statsText} variant="subtitle1">
                <strong>{totalReview}</strong>&ensp;To be reviewed
            </Typography>
            {!isEmpty(filters) && (
                <CustomPopover
                    title={<strong>Vetting Criteria</strong>}
                    className={style.statsText}
                >
                    <Typography fontWeight={700}>Must Haves</Typography>
                    <List>
                        {mustHaveFilters.map((item, index) => (
                            <ListItem
                                key={`${index}${item.text}`}
                                sx={{
                                    display: "list-item",
                                    fontSize: 14,
                                    paddingBottom: 0,
                                }}
                            >
                                {index + 1}. {item.text}
                            </ListItem>
                        ))}
                    </List>
                    <Typography fontWeight={700}>Good To Haves</Typography>
                    <List>
                        {goodToHaveFilters.map((item, index) => (
                            <ListItem
                                key={`${index}${item.text}`}
                                sx={{
                                    display: "list-item",
                                    fontSize: 14,
                                    paddingBottom: 0,
                                }}
                            >
                                {index + 1}. {item.text}
                            </ListItem>
                        ))}
                    </List>
                </CustomPopover>
            )}
        </>
    );
}
