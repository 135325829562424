import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logout, selectUser } from "../../pages/loginPage/LoginPage.reducer";
import {
    Avatar,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemButton,
    Stack,
    Typography,
    Tooltip,
    IconButton,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import AddRole from "../AddRole/AddRole";
import WriteToUs from "../WriteToUs/WriteToUs";
import { useEffect } from "react";
import {
    getCompanyRoles,
    selectCompanyRoles,
} from "../AddRole/AddRole.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { isEmpty } from "lodash";
import CompanyDropdown from "../CompanyDropdown/CompanyDropdown";
import {
    getCompanies,
    selectCompany,
} from "../CompanyDropdown/CompanyDropdown.reducer";
import HqLogo from "../../assets/hqLogo.png";
import UploadLogo from "../UploadLogo/UploadLogo";
import style from "./Sidebar.module.css";
import CleanResume from "../CleanResume/CleanResume";

function stringAvatar(user) {
    if (isEmpty(user)) return;

    const [firstWord, secondWord] = user.name.split(" ");
    return {
        sx: {
            textTransform: "uppercase",
        },
        children:
            user && user.photoUrl
                ? user.photoUrl
                : user && user.name
                ? `${firstWord ? firstWord[0] : ""}${
                      secondWord ? secondWord[0] : ""
                  }`
                : "TU",
    };
}

export default function Sidebar() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const roles = useSelector(selectCompanyRoles);
    const currCompany = useSelector(selectCompany);
    const isCompaniesLoading = useSelector(checkIfLoading(getCompanies.type));
    const isRolesLoading = useSelector(checkIfLoading(getCompanyRoles.type));

    const userRole = user?.role;

    const handleNavigateRole = (id) => {
        navigate(`/${id}`);
    };

    useEffect(() => {
        if (isEmpty(user) || (userRole === "ADMIN" && isEmpty(currCompany)))
            return;

        const company = userRole === "CLIENT" ? user?.company : currCompany._id;
        dispatch(
            getCompanyRoles({
                action: getCompanyRoles.type,
                company,
            })
        );
    }, [user, isCompaniesLoading, currCompany]);

    return (
        <Stack
            height={"100vh"}
            width={256}
            borderRight={"1px solid rgba(0, 0, 0, 0.12)"}
            sx={{
                backgroundColor: "#ffffff",
            }}
        >
            <List sx={{ paddingBottom: 0 }}>
                <ListItem
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        position: "relative",
                        justifyContent: "center",
                    }}
                >
                    <UploadLogo />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar {...stringAvatar(user)} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={user?.name ? user?.name : "Test User"}
                        secondary={user?.email}
                    />
                </ListItem>
                {user?.role === "ADMIN" ? (
                    <ListItem>
                        <CompanyDropdown />
                    </ListItem>
                ) : null}
                <Link to="/overview" className={style["overview-btn"]}>
                    Overview
                </Link>
                <ListItem sx={{ justifyContent: "space-between" }}>
                    <Typography variant="subtitle1">Roles</Typography>
                    <AddRole />
                </ListItem>
            </List>
            <List
                sx={{
                    flex: 1,
                    overflowY: "auto",
                    display: isRolesLoading ? "flex" : "block",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                }}
            >
                {isRolesLoading ? (
                    <CircularProgress size={20} />
                ) : (
                    roles.map((text) => (
                        <ListItemButton
                            key={text._id}
                            selected={id === text._id}
                            onClick={() => handleNavigateRole(text._id)}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    width: "100%",
                                    fontWeight: "bold",
                                    paddingInlineEnd: 2,
                                }}
                            >
                                {text.role}
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    float: "right",
                                    width: "fit-content",
                                }}
                            >
                                {dayjs(text.createdAt).format("MM/DD/YYYY")}
                            </Typography>
                        </ListItemButton>
                    ))
                )}
            </List>
            <Stack mt={"auto"}>
                <Divider />
                <List>
                    {user?.isSuperAdmin ? (
                        <ListItem
                            sx={{
                                paddingTop: 0,
                                paddingBottom: "0.25rem",
                            }}
                        >
                            <CleanResume />
                        </ListItem>
                    ) : null}
                    {user.isSuperAdmin ? (
                        <ListItem
                            sx={{
                                justifyContent: "space-around",
                            }}
                        >
                            <Link
                                to="/users-list"
                                className={style.supportBtn}
                                style={{ marginRight: 10 }}
                            >
                                Users
                            </Link>
                            <Link
                                to="/companies-list"
                                className={style.supportBtn}
                            >
                                Companies
                            </Link>
                        </ListItem>
                    ) : null}
                    {userRole === "CLIENT" ? (
                        <ListItem sx={{ paddingTop: 0 }}>
                            <Link to="/support" className={style.supportBtn}>
                                Support Tickets
                            </Link>
                        </ListItem>
                    ) : null}
                    <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        {userRole === "ADMIN" ? (
                            <Link to="/support" className={style.supportBtn}>
                                Support Tickets
                            </Link>
                        ) : (
                            <WriteToUs />
                        )}
                        <Tooltip title="Logout">
                            <IconButton
                                sx={{ float: "right" }}
                                onClick={() => dispatch(logout())}
                            >
                                <LogoutIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                    {user?.companyDetail?.showFooter ? (
                        <ListItem
                            sx={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography variant="h6">
                                All rights reserved ©
                            </Typography>
                            <img
                                src={HqLogo}
                                alt="hirequotient-logo"
                                height="35"
                            />
                        </ListItem>
                    ) : null}
                </List>
            </Stack>
            <Divider />
        </Stack>
    );
}
