import { createSlice } from "@reduxjs/toolkit";
import { uniqueId } from "lodash";

const initialState = {
    jdPoints: [],
    roles: [],
    currRole: {},
    companyRolesWithStats: [],
};

const reducers = {
    getAnalyzedJd(state, { payload }) {},
    setJDPoints(state, { payload }) {
        state.jdPoints = payload;
    },
    addJdPoint(state, { payload }) {
        state.jdPoints = [
            ...state.jdPoints,
            { id: uniqueId("jdpoint_"), key: 'CUSTOM', text: "", tag: "GOOD" },
        ];
    },
    editJdPoint(state, { payload }) {
        state.jdPoints = state.jdPoints.reduce((acc, curr) => {
            if (curr.id === payload.id) {
                if (payload.name) {
                    acc.push({
                        ...curr,
                        text: payload.name,
                    });
                } else if (payload.type) {
                    acc.push({
                        ...curr,
                        tag: payload.type,
                    });
                }
            } else acc.push(curr);
            return acc;
        }, []);
    },
    deleteJdPoint(state, { payload }) {
        state.jdPoints = state.jdPoints.filter(
            (point) => point.id !== payload
        );
    },
    getCompanyRoles(state, { payload }) {},
    setCompanyRoles(state, { payload }) {
        state.roles = payload;
    },
    getCompanyRolesWithStats() {},
    setCompanyRolesWithStats(state, { payload }) {
        state.companyRolesWithStats = payload;
    },
    createJob(state, { payload }) {},
    editJob(state, { payload }) {},
    deleteJob(state, { payload }) {},
    setCurrentRole(state, { payload }) {
        state.currRole = payload;
    },
    leaveJd(state) {},
};

export const reducersPage = createSlice({
    name: "addRole",
    initialState,
    reducers,
});

export default reducersPage.reducer;

export const {
    getAnalyzedJd,
    setJDPoints,
    addJdPoint,
    editJdPoint,
    deleteJdPoint,
    getCompanyRoles,
    setCompanyRoles,
    createJob,
    editJob,
    deleteJob,
    setCurrentRole,
    getCompanyRolesWithStats,
    setCompanyRolesWithStats,
    leaveJd,
} = reducersPage.actions;

export const selectJdPoints = (state) => state.addRole.jdPoints;
export const selectCompanyRoles = (state) => state.addRole.roles;
export const selectCurrRole = (state) => state.addRole.currRole;
export const selectCompanyRolesWithStats = (state) =>
    state.addRole.companyRolesWithStats;
