import { Button, CircularProgress } from "@mui/material";

export default function ButtonTextWithLoading({
    isLoading = false,
    text,
    loaderSize = 20,
    loaderColor = "#fff",
    buttonStyle,
    ...rest
}) {
    return (
        <Button
            disabled={isLoading}
            sx={{
                padding: isLoading ? "0.5rem 1rem" : "6px 16px",
                ...buttonStyle,
            }}
            {...rest}
        >
            {isLoading ? (
                <CircularProgress
                    size={loaderSize}
                    sx={{
                        color: loaderColor,
                    }}
                />
            ) : (
                text
            )}
        </Button>
    );
}
