import { get, isEmpty } from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setSuccessNotification } from "../../components/Notification/Notification.reducer";
import { startAction, stopAction } from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import { CancelSagas } from "../../utils/saga.utils";
import {
    createJob,
    deleteJob,
    editJob,
    getAnalyzedJd,
    getCompanyRoles,
    getCompanyRolesWithStats,
    leaveJd,
    setCompanyRoles,
    setCompanyRolesWithStats,
    setCurrentRole,
    setJDPoints,
} from "./AddRole.reducer";

function* getAnalyzedJdSaga({ payload }) {
    try {
        const { jd, supportingDocuments, role, action } = payload;

        yield put(startAction({ action }));

        const state = yield select();
        const userStateValue = get(state, "signin.user");
        const currCompany = get(state, "company.currCompany");
        const company =
            userStateValue.role === "ADMIN"
                ? currCompany._id
                : userStateValue.company;

        const formData = new FormData();
        formData.append("role", role);
        [].forEach((item) => {
            formData.append("filters", item);
        });
        formData.append("company", company);
        formData.append("jd", jd);

        if (!isEmpty(supportingDocuments)) {
            for (let i = 0; i < supportingDocuments.length; i++) {
                formData.append("supportingDocuments", supportingDocuments[i]);
            }
        }

        const response = yield call(new API().post, "/jobs/gpt", formData);
        yield put(setJDPoints(response.filters));
        yield put(setCurrentRole(response));
    } catch (error) {
        console.error("***getAnalyzedJdSagaError=", error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getCompanyRolesSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().get,
            `/jobs/company/${payload.company}`
        );
        yield put(setCompanyRoles(response));
    } catch (error) {
        console.error("***getCompanyRolesSagaError=", error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* createJobSaga({ payload }) {
    try {
        const {
            role,
            filters,
            jd,
            supportingDocuments,
            company,
            description,
            action,
        } = payload;

        yield put(startAction({ action }));

        const response = yield call(new API().post, `/jobs`, {
            role,
            filters,
            jd,
            company,
            description,
            supportingDocuments,
        });
        if (isEmpty(response)) return;

        const state = yield select();
        const rolesStateValue = get(state, "addRole.roles");
        yield put(setCompanyRoles([...rolesStateValue, response]));
    } catch (error) {
        console.error("***createJobSagaError=", error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editJobSaga({ payload }) {
    try {
        const {
            action,
            role,
            status,
            filters,
            company,
            id,
            jd,
            supportingDocuments,
        } = payload;

        yield put(startAction({ action }));

        const state = yield select();
        const rolesStateValue = get(state, "addRole.roles");
        const stage1StateValue = get(state, "editRole.stage1");
        const stage2StateValue = get(state, "editRole.stage2");
        const stage3StateValue = get(state, "editRole.stage3");

        const formData = new FormData();
        formData.append("role", role);
        formData.append("status", status);
        formData.append("filters", JSON.stringify(filters));
        formData.append("company", company);
        if (!isEmpty(stage1StateValue)) {
            formData.append("stage1", JSON.stringify(stage1StateValue));
        }
        if (!isEmpty(stage2StateValue)) {
            formData.append("stage2", JSON.stringify(stage2StateValue));
        }
        if (!isEmpty(stage3StateValue)) {
            formData.append("stage3", JSON.stringify(stage3StateValue));
        }
        const jobDescription = jd instanceof FileList ? jd[0] : jd;
        if (jobDescription) formData.append("jd", jobDescription);
        if (!isEmpty(supportingDocuments)) {
            for (let i = 0; i < supportingDocuments.length; i++) {
                formData.append("supportingDocuments", supportingDocuments[i]);
            }
        }

        const response = yield call(new API().put, `/jobs/${id}`, formData);

        const tmpCompanyRoles = rolesStateValue.map((item) => {
            if (item._id === id) {
                return response;
            }
            return item;
        });
        yield put(setCompanyRoles(tmpCompanyRoles));
        yield put(setSuccessNotification("Role edited successfully"));
    } catch (error) {
        console.error("***editJobSagaError=", error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getCompanyRolesWithStatsSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().get,
            `/jobs/company-stats/${payload.company}`
        );
        yield put(setCompanyRolesWithStats(response));
    } catch (error) {
        console.error("***getCompanyRolesWithStatsSagaError=", error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteJobSaga({ payload }) {
    try {
        yield put(startAction({ action: payload.action }));
        yield call(new API().delete, `/jobs/${payload.id}`);
        yield put(setSuccessNotification("Role deleted successfully"));
        payload.navigate("/");
    } catch (error) {
        console.error("***getCompanyRolesWithStatsSagaError=", error);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        yield takeLatest(getAnalyzedJd.type, getAnalyzedJdSaga),
        yield takeLatest(getCompanyRoles.type, getCompanyRolesSaga),
        yield takeLatest(
            getCompanyRolesWithStats.type,
            getCompanyRolesWithStatsSaga
        ),
        yield takeLatest(createJob.type, createJobSaga),
        yield takeLatest(editJob.type, editJobSaga),
        yield takeLatest(deleteJob.type, deleteJobSaga),
    ];
    yield takeLatest(leaveJd.type, CancelSagas, tasks);
}
