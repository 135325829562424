import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Stack, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

import {
    acceptStyle,
    focusedStyle,
    rejectStyle,
    supportBaseStyle,
} from "../../utils/helper";

export default function SupportingDocuments({ handleSupportDocuments }) {
    const onDrop = useCallback((acceptedFiles) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleSupportDocuments(files);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
        acceptedFiles,
    } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"],
        },
        maxFiles: 5,
        onDrop,
    });

    const acceptedFileItems = acceptedFiles.map((file) => (
        <Stack
            key={file.path}
            direction={"row"}
            alignItems={"center"}
            spacing={0.5}
        >
            <DescriptionIcon />
            <Typography>{file.path}</Typography>
        </Stack>
    ));

    const style = useMemo(
        () => ({
            ...supportBaseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive
                    ? "Drop supporting documents here ..."
                    : "Click to upload any supporting documents or drag and drop"}
            </div>
            <Stack mt={1} spacing={0.5}>
                {acceptedFileItems}
            </Stack>
        </>
    );
}
