import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Poppins",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        h1: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 25,
            lineHeight: "48px",
            color: "#000000",
            opacity: 0.8,
        },
        h2: {
            marginBottom: 10,
            opacity: 0.8,
        },
        h6: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 10,
            lineHeight: "18px",
            color: "#18345E",
            opacity: 0.8,
        },
        subtitle2: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 12,
            lineHeight: "18px",
            color: "#18345E",
            opacity: 0.8,
        },
        subtitle1: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "22px",
            color: "#4E4E4E",
            opacity: 0.8,
        },
        body1: {
            fontSize: 14,
            color: "#44ABE1",

            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "15px",
            opacity: 0.8,
        },
        body2: {
            fontSize: 12,
            color: "#44ABE1",

            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "15px",
            opacity: 0.8,
        },
    },
    palette: {
        primary: {
            main: "#479bd2",
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    subtitle1: "h2",
                    subtitle2: "h2",
                    body1: "span",
                    body2: "span",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: "5px 15px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "12px",
                    boxShadow:
                        "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
                    borderRadius: 32,
                    ":hover": {
                        background: "#479bd2",
                        color: "#ffffff",
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    zIndex: 999999,
                },
                tooltip: {
                    fontSize: 10,
                },
            },
        },
        // MuiInputBase: {
        //     styleOverrides: {
        //         input: {
        //             "::-webkit-input-placeholder": {
        //                 color: "#cccccc",
        //             },
        //             ":placeholder": {
        //                 color: "#000",
        //             },
        //         },
        //     },
        // },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    background: "#EEEEEE",
                    fontWeight: 700,
                    fontSize: "14px",
                    opacity: 1,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: "16px",
                    color: "#4E4E4E",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "0 24px 20px",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: "center",
                },
                message: {
                    fontSize: 14,
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    opacity: 1,
                },
                columnHeader: {
                    background: "#EEEEEE",
                    color: "#000",
                },
                columnHeaderTitle: {
                    fontSize: 14,
                    fontWeight: 700,
                },
                row: {
                    backgroundColor: "#fff",
                },
                cellContent: {
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: 12,
                    opacity: 0.7,
                },
            },
        },
    },
});
