import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { Alert, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { selectCompanyRoles } from "../../components/AddRole/AddRole.reducer";
import {
    selectStage1,
    selectStage2,
    selectStage3,
    setStage,
    stageInitialState,
} from "../../components/EditRole/editRole.slice";
import { useEffect } from "react";
import dayjs from "dayjs";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    timelineContentClasses,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
    TimelineSeparator,
} from "@mui/lab";

export default function RoleProgress() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const roles = useSelector(selectCompanyRoles);
    const currentRole = roles.find((item) => item._id === id);

    const stage1 = useSelector(selectStage1);
    const stage2 = useSelector(selectStage2);
    const stage3 = useSelector(selectStage3);

    const [openStages, setOpenStages] = useState({
        stage1: false,
        stage2: false,
        stage3: false,
    });

    useEffect(() => {
        if (isEmpty(currentRole)) return;

        const {
            stage1 = stageInitialState,
            stage2 = stageInitialState,
            stage3 = stageInitialState,
        } = currentRole;
        const stages = { stage1, stage2, stage3 };

        Object.entries(stages).forEach(([key, value]) => {
            dispatch(setStage({ key, value }));
        });
    }, [currentRole]);

    const handleToggleOpen = (stageId) => {
        setOpenStages((prevState) => ({
            ...prevState,
            [stageId]: !prevState[stageId],
        }));
    };

    const renderStageButton = (label, stage, stageId) => {
        if (!isEmpty(stage.points)) {
            return (
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot
                            color={openStages[stageId] ? "primary" : "grey"}
                        />
                        <TimelineConnector
                            sx={{
                                bgcolor: openStages[stageId]
                                    ? "primary.main"
                                    : "#bdbdbd",
                            }}
                        />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={1}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                width="fit-content"
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    p={1}
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.04)",
                                        },
                                    }}
                                    onClick={() => handleToggleOpen(stageId)}
                                    spacing={1}
                                >
                                    <Typography
                                        fontSize={16}
                                        fontWeight={600}
                                        color={"#18345E"}
                                    >
                                        {label}
                                    </Typography>
                                    <ArrowDropDownIcon />
                                </Stack>
                                <Tooltip title={stage.info} placement="right">
                                    <IconButton size="small">
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Typography
                                fontSize={16}
                                fontWeight={600}
                                color={"#18345E"}
                            >
                                Started on: {stage.date}
                            </Typography>
                        </Stack>
                        {openStages[stageId] ? (
                            <>
                                {stage.summary ? (
                                    <Alert icon={false} severity="info">
                                        {stage.summary}
                                    </Alert>
                                ) : null}
                                <Timeline
                                    sx={{
                                        [`& .${timelineContentClasses.root}`]: {
                                            padding: "10px 0 10px 16px",
                                        },
                                        [`& .${timelineOppositeContentClasses.root}`]:
                                            {
                                                flex: 0.1,
                                                padding: "10px 16px 10px 0",
                                            },
                                    }}
                                >
                                    {stage.points.map((point, index) => (
                                        <TimelineItem
                                            sx={{
                                                minHeight:
                                                    stage.points.length - 1 !==
                                                    index
                                                        ? "70px"
                                                        : "fit-content",
                                            }}
                                        >
                                            <TimelineOppositeContent color="textSecondary">
                                                {point.date}
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                {stage.points.length - 1 !==
                                                index ? (
                                                    <TimelineConnector />
                                                ) : null}
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                {point.text}
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                            </>
                        ) : null}
                    </TimelineContent>
                </TimelineItem>
            );
        }
        return null;
    };

    return (
        <Stack
            padding={2}
            flex={1}
            maxHeight="calc(100vh - 40px)"
            overflow="auto"
        >
            {!isEmpty(currentRole) ? (
                <Typography mb={2} fontSize={16} color="black">
                    <Typography
                        fontSize={16}
                        fontWeight={600}
                        sx={{
                            textDecoration: "underline",
                        }}
                    >
                        {currentRole.role}
                    </Typography>{" "}
                    activated on{" "}
                    {dayjs(currentRole.updatedAt)
                        .locale("en")
                        .format("M/D/YYYY, hh:mm A")}
                </Typography>
            ) : null}
            <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                    "& .MuiTypography-root": {
                        opacity: 1,
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                }}
            >
                {renderStageButton("Stage 1", stage1, "stage1")}
                {renderStageButton("Stage 2", stage2, "stage2")}
                {renderStageButton("Stage 3", stage3, "stage3")}
            </Timeline>
        </Stack>
    );
}
