import { createSlice } from "@reduxjs/toolkit";
import { dynamicSort } from "../../utils/helper";

const initialState = {
    companies: [],
    currCompany: {},
};

const reducers = {
    getCompanies(state, { payload }) {},
    setCompanies(state, { payload }) {
        payload.sort(dynamicSort("name", 1));
        state.companies = payload;
    },
    setCurrCompany(state, { payload }) {
        state.currCompany = payload;
    },
    leaveCompany(state) {},
};

export const reducersPage = createSlice({
    name: "company",
    initialState,
    reducers,
});

export default reducersPage.reducer;

export const { getCompanies, setCompanies, setCurrCompany, leaveCompany } =
    reducersPage.actions;

export const selectCompanies = (state) => state.company.companies;
export const selectCompany = (state) => state.company.currCompany;
