import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
    addJdPoint,
    deleteJdPoint,
    selectJdPoints,
} from "../AddRole/AddRole.reducer";
import JDInput from "./Input";
import RadioButton from "./RadioButton";
import StyledTableCell from "../CustomMUIComponents/TableCell";

export default function JDPoints() {
    const dispatch = useDispatch();
    const jdPoints = useSelector(selectJdPoints);

    const handleAddPoint = () => {
        dispatch(addJdPoint());
    };

    const handleDeletePoint = (id) => {
        dispatch(deleteJdPoint(id));
    };

    return (
        <>
            <TableContainer
                component={Paper}
                sx={{ width: 800, maxHeight: 500 }}
            >
                <Table stickyHeader aria-label="jd-points-table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width={"50%"}>
                                Vetting Criteria
                            </StyledTableCell>
                            <StyledTableCell width={"15%"}>
                                Must Have
                            </StyledTableCell>
                            <StyledTableCell width={"15%"}>
                                Good To Have
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jdPoints.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell component="th" scope="row">
                                    <Stack direction={"row"} spacing={2}>
                                        <Tooltip title="Remove criteria">
                                            <IconButton
                                                color="error"
                                                onClick={() =>
                                                    handleDeletePoint(item.id)
                                                }
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <JDInput item={item} />
                                    </Stack>
                                </TableCell>
                                <RadioButton item={item} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Tooltip title="Add new vetting criteria">
                <Button
                    variant={"contained"}
                    sx={{
                        borderRadius: "50%",
                        minWidth: 42,
                        height: 40,
                        padding: 0,
                        marginTop: 1,
                    }}
                    onClick={handleAddPoint}
                >
                    <AddIcon />
                </Button>
            </Tooltip>
        </>
    );
}
